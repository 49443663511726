import { Box, Container, Grid, makeStyles, Paper, Typography, Link } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import carta from '../assets/images/carta_background.jpg';
import marinha from '../assets/images/marinha.png';
import chm from '../assets/images/chm.png';
import dhn from '../assets/images/dhn.png';
const useStyles = makeStyles((theme) => ({
    section: {
        height: '90vh',
        // backgroundImage: "url(" + carta + ")",
        // opacity: '0.5',
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(` + carta + `)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        filter: "“rightness(60%)",
        [theme.breakpoints.down('sm')]: {
            height: '100vh',
        }
    },
    content: {
        height: "100%"
    },
    container: {
        height: "100%"
    },
    box: {
        marginTop: theme.spacing(5),

    },
    gridItem: {
        color: grey[50],
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
        }
    },
    gridItemH1: {
        color: grey[50],
        fontWeight: '400',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
        }
    },
    logo: {
        height: '40px',
        width: "40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            height: '35px',
            width: "35px",
        }
    },
    om: {
        display: "flex",
        marginTop: theme.spacing(1),
        '&:hover': {
            cursor:'pointer'
        },

    }
}));

const Hero = () => {
    const classes = useStyles();
    const handleClick = (link) => {
        window.open(link, '_blank');
    }
    return (
        <Paper className={classes.section}>
            <Container className={classes.container}>
                <Grid className={classes.content} container alignItems="center">
                    <Grid item>
                        <Typography className={classes.gridItemH1} variant="h1">
                            Infraestrutura de Dados Espaciais Marinhos
                        </Typography>
                        <Box className={classes.box}>
                            <Box className={classes.om} onClick={() => handleClick('https://www.marinha.mil.br/')}>
                                <img alt="" className={classes.logo} src={marinha} />
                                <Typography href="marinha.mil.br/" className={classes.gridItem} variant="h5">
                                    Marinha do Brasil
                                </Typography>
                            </Box>
                            <Box className={classes.om} onClick={() => handleClick('https://www.marinha.mil.br/dhn')}>
                                <img alt="" className={classes.logo} src={dhn} />
                                <Typography className={classes.gridItem} variant="h5">
                                    Diretoria de Hidrografia e Navegação
                                </Typography>
                            </Box>
                            <Box className={classes.om} onClick={() => handleClick('https://www.marinha.mil.br/chm')}>
                                <img alt="" className={classes.logo} src={chm} />
                                <Typography className={classes.gridItem} variant="h5">
                                    Centro de Hidrografia da Marinha
                                </Typography>
                            </Box>
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Paper>
    )
}

export default Hero;