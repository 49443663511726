import {
  Button,
  Drawer,
  makeStyles,
  Slider,
  Typography,
  Tooltip,
  Box,
  withStyles,
  Fab,
} from '@material-ui/core';
import axios from 'axios';
import React, { Component, useEffect, useState } from 'react';
import {
  FeatureGroup,
  LayersControl,
  Marker,
  Popup,
  useMap,
} from 'react-leaflet';
import XMLParser from 'react-xml-parser';
import { WMSTileLayer } from 'react-leaflet';
import { findByLabelText } from '@testing-library/react';
import { blue, blueGrey } from '@material-ui/core/colors';
import {
  DesktopAccessDisabledSharp,
  Height,
  ImportantDevices,
} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import PauseIcon from '@material-ui/icons/Pause';
import { tooltip } from 'leaflet';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Launch from '@material-ui/icons/Launch';
import Zoom from '@material-ui/core/Zoom';
import CircularProgress from '@material-ui/core/CircularProgress';

const CartaSinoticaTooltip = withStyles((theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    //color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    zIndex: 2,
    // transition: theme.transitions.create('width', {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    // background: 'rgba(0, 0, 0, 0)',
  },

  btn: {
    margin: theme.spacing(1),
    height: theme.spacing(4),
    width: theme.spacing(4),
    // height: "21px",
    marginTop: '30px',
  },

  play: {
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '15px',
  },
  pause: {
    border: '2px solid',
    borderColor: theme.palette.warning.main,
    borderRadius: '15px',
    color: theme.palette.warning.main,
    marginRight: theme.spacing(1),
  },
  stop: {
    border: '2px solid',
    borderColor: theme.palette.error.main,
    borderRadius: '15px',
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },

  slider: {
    margin: theme.spacing(2),
    maxWidth: '100vh',
    padding: '29px 0px 29px 0px',
  },
  typography: {
    textAlign: 'center',
  },
  fab: {
    position: 'fixed',
    top: theme.spacing(54),
    left: theme.spacing(4),
    zIndex: 1000,
    background: '#FFF',
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#FFF',
    },
    // bottom: theme.spacing(2),
    // right: theme.spacing(2),
  },
  progress: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    top: '50%',
    left: '0',
    right: '0',
    textAlign: 'center',
    zIndex: 1000,
  },
}));

const CartaSinoticaLayer = (props) => {
  // const { url, options, layers } = props;

  const map = useMap();
  const [open, setOpen] = useState(true);
  const [layers, setLayers] = useState([]);
  const [wmsLayer, setWmsLayer] = useState();
  const [wmsLayerList, setWmsLayerList] = useState([]);
  const [sliderValue, setSliderValue] = useState(0);
  const classes = useStyles();
  const [timerStack, setTimerStack] = useState([]);
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
    // CartaSinoticaTooltip.open(false)
  };

  const handleDrawerClose = () => {
    setOpen(false);
    // CartaSinoticaTooltip.open(false)
  };

  // const api = axios.create({
  //   baseURL:
  //     'https://idem.dhn.mar.mil.br/geoserver/carta_sinotica/ows?service=wms&version=1.3.0&request=GetCapabilities',
  // });

  const api = axios.create({
    baseURL: process.env.REACT_APP_SINOTICA_URL,
  });

  const getCapabilities = async () => {
    api
      .get()
      .then((res) => {
        let dict = [];
        const xml = new XMLParser().parseFromString(res.data);
        const layers = xml.getElementsByTagName('Layer');

        layers.splice(0, 1); //retirando elemento Geoserver WMS
        layers.forEach((layer) => {
          dict.push(layer.children[1].value);
        });
        setLayers(dict);
        // wmsLayer
        //   ? setWmsLayer(wmsLayer)
        setWmsLayer('carta_sinotica:' + dict[layers.length - 1]);

        setSliderValue(layers.length - 1);
        // setWmsLayer("carta_sinotica:" + layers[sliderValue]);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    getCapabilities();
    if (!props.checked) {
      props.setIsCartaSinoticaButtonOpened(false);
    }
  }, [props.checked]);

  const handleSliderChange = (event, value) => {
    map.dragging.disable();
    setSliderValue(value);
    setWmsLayer('carta_sinotica:' + layers[sliderValue]);
    map.dragging.enable();
  };

  const labelSliderName = (value) => {
    return `${value[5]}${value[6]}/${value[3]}${value[4]}/${value[1]}${value[2]} ${value[7]}${value[8]}Z`;
  };

  const valuetext = (value) => {
    return layers[value];
  };

  const valueLabelComponent = (props) => {
    const { children, value } = props;

    return (
      <>
        <CartaSinoticaTooltip
          open={open}
          enterTouchDelay={0}
          title={labelSliderName(value)}
        >
          {children}
        </CartaSinoticaTooltip>
      </>

      // <>
      //     <Typography>{value}</Typography>
      //     <Typography>{children}</Typography>
      // </>
    );
  };

  const addStack = (i, initialValue) => {
    const timeOut = setTimeout(() => {
      setSliderValue(i + initialValue);
      setWmsLayer('carta_sinotica:' + layers[i + initialValue]);
      // console.log(i + initialValue,"carta_sinotica:" + layers[i + initialValue]);
      if (i + initialValue === layers.length - 1) {
        setPlaying(false);
      }
    }, 2000 * i + 200);
    setTimerStack((timerStack) => [...timerStack, timeOut]);
  };

  const startPassLayers = (event, action) => {
    event.preventDefault();
    if (action === 'stop') {
      setSliderValue(layers.length - 1);
      setWmsLayer('carta_sinotica:' + layers[layers.length - 1]);
      setPlaying(false);
      timerStack.forEach((timer) => {
        clearTimeout(timer);
      });
      setTimerStack([]);
    }
    if (action === 'play') {
      // for (let i = sliderValue === (layers.length - 1) ? layers.length - 1 : ((layers.length - 1) - sliderValue);
      //   i >= (sliderValue === (layers.length - 1) ? 0 : sliderValue);
      //   i--)
      const initialValue = sliderValue === layers.length - 1 ? 0 : sliderValue;
      for (let i = 0; i <= layers.length - 1 - initialValue; i++) {
        addStack(i, initialValue);
      }
      setPlaying(true);
    }
    if (action === 'pause') {
      setPlaying(false);
      timerStack.forEach((timer) => {
        clearTimeout(timer);
      });
      setTimerStack([]);
    }
  };

  const btnPlay = () => {
    return (
      <Tooltip title='Iniciar Animação' placement='bottom'>
        <PlayArrowIcon
          onClick={(event) => {
            startPassLayers(event, 'play');
          }}
          className={classes.play}
        />
      </Tooltip>
    );
  };

  const btnStop = () => {
    return (
      <Tooltip title='Parar Animação' placement='bottom'>
        <StopIcon
          onClick={(event) => {
            startPassLayers(event, 'stop');
          }}
          className={classes.stop}
        />
      </Tooltip>
    );
  };

  const btnPause = () => {
    return (
      <Tooltip title='Pausar Animação' placement='bottom'>
        <PauseIcon
          onClick={(event) => {
            startPassLayers(event, 'pause');
          }}
          className={classes.pause}
        />
      </Tooltip>
    );
  };

  return (
    <>
      {layers.length > 0 && wmsLayer ? (
        <>
          {open ? null : (
            <Zoom in={!open}>
              <Tooltip title='Maximizar Controle de Animação' placement='right'>
                <Fab
                  color='primary'
                  aria-label='minimize'
                  onClick={() => {
                    handleDrawerOpen();
                    props.setIsCartaSinoticaButtonOpened(false);
                  }}
                  className={classes.fab}
                >
                  <Launch />
                </Fab>
              </Tooltip>
            </Zoom>
          )}

          <Drawer
            id='drawer'
            anchor='top'
            open={open}
            variant='persistent'
            onClose={handleDrawerClose}
            className={classes.drawerPaper}
          >
            <Box className={classes.box}>
              {/* <Typography className={classes.typography}>
                  Carta Sinótica
                </Typography> */}
              {/*  <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PlayArrowIcon className={classes.play} />}
                  className={classes.btn}
                >
                </Button>*/}

              <IconButton className={classes.btn} color='primary'>
                {playing ? btnPause() : null}
                {playing ? btnStop() : btnPlay()}
              </IconButton>
              <Slider
                ValueLabelComponent={valueLabelComponent}
                value={sliderValue}
                valueLabelFormat={valuetext}
                aria-labelledby='discrete-slider'
                valueLabelDisplay='on'
                onChange={handleSliderChange}
                step={1}
                marks
                min={0}
                max={layers.length - 1}
                className={classes.slider}
              />
              <Tooltip title='Minimizar Controle de Animação' placement='right'>
                <IconButton
                  className={classes.btn}
                  color='primary'
                  onClick={() => {
                    handleDrawerClose();
                    props.setIsCartaSinoticaButtonOpened(true);
                  }}
                >
                  <HighlightOffIcon className={classes.btnFechar} />
                </IconButton>
              </Tooltip>
            </Box>
          </Drawer>

          {/* {layers.map((layer) => { */}
          {/* return ( */}
          {/* <LayersControl.Overlay checked={wmsLayer.split(":")[1] === layers[sliderValue]}> */}
          <LayersControl position='topleft' hideSingleBase={true}>
            <LayersControl.Overlay checked={true}>
              <FeatureGroup>
                <WMSTileLayer
                  key={sliderValue}
                  url={`${process.env.REACT_APP_IDEM_URL}/geoserver/ows?SERVICE=WMS&`}
                  noRedraw={false}
                  // layers={"carta_sinotica:" + layers[sliderValue]}
                  layers={wmsLayer}
                  format='image/png'
                  transparent={true}
                  zIndex={props.activeLayers.length + 1}
                  opacity={1}
                  eventHandlers={{
                    tileload: () => {
                      setLoading(false);
                    },
                    loading: () => {
                      setLoading(true);
                    },
                  }}
                />
              </FeatureGroup>
            </LayersControl.Overlay>
          </LayersControl>

          {loading ? (
            <CircularProgress size={70} className={classes.progress} />
          ) : null}

          {/* ); */}
          {/* })} */}
        </>
      ) : null}
    </>
  );

  // // Add WMS source/layers
  // const source = WMS.source(url, options);

  // for (let name of layers) {
  //   source.getLayer(name).addTo(map);
  // }

  // map.on('click', function (e) {
  //   // Build the URL for a GetFeatureInfo
  //   console.log(source.getLayer(layers[0]));
  // });
};

export default CartaSinoticaLayer;
