import React, { Component, useEffect, useState } from 'react';
import { CircleMarker, Tooltip, useMap, useMapEvents, WMSTileLayer } from 'react-leaflet';
import { Typography, makeStyles } from "@material-ui/core";
import DmsCoordinates from 'dms-conversion';


const useStyles = makeStyles((theme) => ({
    latlong: {
        position: "fixed",
        top: '95%',
        left: '89%',
        background: '#FFF',
        padding: theme.spacing(0.5),
        zIndex: 9999,
        [theme.breakpoints.down('sm')]: {
            top: '90%',
            left: '70%',
            marginRight: theme.spacing(10)
        }
    },
    latlongValue: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        }
    }

}));

const convertLatLong = (long, lat) => {

    const dmsCoords = new DmsCoordinates(long, lat);
    const { longitude, latitude } = dmsCoords.dmsArrays;
    let [dLong, mLong, sLong, nsewLong] = longitude;
    let [dLat, mLat, sLat, nsewLat] = latitude;
    sLong = sLong.toFixed(2);
    sLat = sLat.toFixed(2);


    return `${dLat}°${mLat}'${sLat}''${nsewLat}, ${dLong}°${mLong}'${sLong}''${nsewLong}`

}

const LatLong = (props) => {
    const classes = useStyles();
    const [latLong, setLatLong] = useState(convertLatLong(-22.884600, -43.1334));

    const mapEvents = useMapEvents({
        mousemove: e => {
            try {
                if (e.latlng.lat >= -90 && e.latlng.lat <= 90 && e.latlng.lng >= -180 && e.latlng.lng <= 180) {
                    setLatLong(convertLatLong(e.latlng.lat, e.latlng.lng));
                }
            } catch (ex) {
                console.log(ex)
            }
        },
    })



    return (
        <div className={classes.latlong}>
            <Typography className={classes.latlongValue} variant="body2">
                {latLong}
            </Typography>
        </div>
    )


}


export default LatLong;
