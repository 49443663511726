import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useEffect } from 'react';
const useStyles = makeStyles((theme) => ({
  divider: {
    background: '#3f51b5',
  },
  button: {
    // color: 'white',
    // background: '#3f51b5',
    // '&:hover': { backgroundColor: '#041581' },
    color: '#3f51b5',
    border: '1px solid #3f51b5',
    background: 'white',
    '&:hover': {
      color: '#041581',
      border: '1px solid #3f51b5',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    },
  },
}));
// cons

const ShowMetadataDialog = ({
  title,
  layers,
  activeLayer,
  metadataInfo,
  isShowMetadataDialogOpen,
  setIsShowMetadataDialogOpen,
}) => {
  const classes = useStyles();

  //getAbstract = () => {
  //   console.log('title', title);

  //   let res = '';
  //   for (const layer in layers) {
  //     layers[layer].forEach((obj) => {
  //       if (obj.name === title) {
  //         res = obj.abstract;
  //         console.log('obj.name', obj.name);

  //         console.log('res', res);
  //       }
  //     });
  //   }
  //   return res;
  // };

  const handleClose = () => {
    setIsShowMetadataDialogOpen(false);
  };

  // let abstract = '';
  // abstract = getAbstract();

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 320,
        bgcolor: 'background.paper',
      }}
    >
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 435 } }}
        maxWidth='sm'
        open={isShowMetadataDialogOpen}
      >
        <DialogTitle>
          <Box>
            <Typography variant='h5'>{metadataInfo.currentTitle}</Typography>
          </Box>
        </DialogTitle>
        <Divider variant='middle' classes={{ root: classes.divider }} />
        <DialogContent>
          <Box display='flex' flexDirection='column' sx={{ gap: '20px' }}>
            <Box>
              <Typography variant='h6'>Resumo</Typography>
              <Typography variant='subtitle1'>
                {metadataInfo.currentAbstract}
              </Typography>
            </Box>
            <Box>
              <Typography variant='h6'>Responsável</Typography>
              <Typography variant='subtitle1'>
                Centro de Hidrografia da Marinha - CHM
              </Typography>
            </Box>
            <Box>
              <Typography variant='h6'>Organização Responsável</Typography>
              <Typography variant='subtitle1'>
                Diretoria de Hidrografia e Navegação - DHN
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <Divider variant='middle' classes={{ root: classes.divider }} />

        <DialogActions>
          <Button
            size='small'
            onClick={() => handleClose()}
            variant='outlined'
            classes={{ root: classes.button }}
            // style={{
            //   color: 'white',
            //   backgroundColor: '#3f51b5',
            //   '&:hover': { backgroundColor: '#041581' },
            // }}
          >
            FECHAR
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ShowMetadataDialog;
