import { Grid, Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import batimetria from '../assets/images/batimetria.jpg';
import Footer from '../components/Footer';

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: theme.spacing(15),
    marginRight: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
    },
    marginBottom: theme.spacing(5),
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    padding: theme.spacing(5),
    textAlign: 'center',
    width: '100%',
    maxWidth: '100%',
    maxHeigth: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary,
    '&:visited': {
      textDecoration: 'inherit',
      color: 'transparent',
    },
  },
  subTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

const Idem = () => {
  const classes = useStyles();
  const idem_url = `${process.env.REACT_APP_IDEM_URL}`;
  const portaria13_url =
    'https://www.marinha.mil.br/dhn/sites/www.marinha.mil.br.dhn/files/Port13-2018-DHN-Aprova-NAD-DHN.pdf';
  const inde = 'https://inde.gov.br/';
  const decreto_6666 =
    'http://www.planalto.gov.br/ccivil_03/_Ato2007-2010/2008/Decreto/D6666.html';
  const pem = 'https://www.marinha.mil.br/secirm/psrm/pem';
  const ccirm = 'https://www.marinha.mil.br/secirm/';
  const bndo = 'https://www.marinha.mil.br/chm/bndo';
  const decreto_5jan = '';
  const iode = 'https://www.iode.org/';
  const coi = 'https://ioc.unesco.org/';
  const msdiwg = 'https://iho.int/en/msdiwg';
  const ohi = 'https://iho.int/en/';
  return (
    <Box className={classes.container}>
      <Grid container xs={12}>
        <Grid className={classes.title} item xs={12}>
          <Typography align='center' variant='h4'>
            Infraestrutura de Dados Espaciais Marinhos da Diretoria de
            Hidrografia e Navegação – IDEM-DHN
          </Typography>
        </Grid>

        <Grid item md={6} sm={12}>
          <Typography className={classes.subTitle} align='center' variant='h6'>
            Propósito
          </Typography>
          <Typography align='left' variant='body1'>
            A IDEM-DHN foi concebida com o propósito de catalogar, integrar e
            harmonizar dados geoespaciais abertos produzidos ou mantidos e
            geridos pela Diretoria de Hidrografia e Navegação, de modo que
            possam ser facilmente localizados, explorados em suas
            características e acessados para os mais variados fins por qualquer
            usuário com acesso à Internet.Os dados e informações abertos
            produzidos ou mantidos e geridos pela Diretoria de Hidrografia e
            Navegação estão descritos na{' '}
            <a className={classes.link} href={portaria13_url}>
              Portaria n°13/2018 da Diretoria de Hidrografia e Navegação
            </a>
            .
          </Typography>
          <Typography className={classes.subTitle} align='center' variant='h6'>
            Objetivos
          </Typography>
          <Typography align='left' variant='body1'>
            A{' '}
            <a className={classes.link} href={idem_url}>
              IDEM-DHN
            </a>{' '}
            visa atender compromissos nacionais e internacionais de intercâmbio
            de dados ambientais marinhos, dente eles:
            <br />
            1. O Plano de Ação para Implantação da{' '}
            <a className={classes.link} href={inde}>
              Infraestrutura Nacional de Dados Espaciais – INDE
            </a>{' '}
            instituída pelo
            <a className={classes.link} href={decreto_6666}>
              {' '}
              Decreto Nº 6.666 de 27/11/2008;
            </a>
            <br />
            2.{' '}
            <a className={classes.link} href={pem}>
              Planejamento Espacial Marinho – PEM
            </a>{' '}
            da Amazônia Azul coordenado pela{' '}
            <a className={classes.link} href={ccirm}>
              Comissão Interministerial para os Recursos do Mar
            </a>
            ;
            <br />
            3. Servir de{' '}
            <a className={classes.link} href={bndo}>
              Banco Nacional de Dados Oceanográficos – BNDO
            </a>{' '}
            conforme estabelecido no
            <a className={classes.link} href={decreto_5jan}>
              Decreto de 5 de janeiro de 1994
            </a>
            ;
            <br />
            4. Os requisitos do{' '}
            <a className={classes.link} href={iode}>
              Programa Internacional de Intercâmbio de Dados e Informações
              Oceanográficas – IODE
            </a>
            da{' '}
            <a className={classes.link} href={coi}>
              {' '}
              Comissão Oceanográfica Intergovernamental – COI{' '}
            </a>{' '}
            da UNESCO; e
            <br />
            5. O{' '}
            <a className={classes.link} href={msdiwg}>
              Grupo de Trabalho Infraestruturas de Dados Espacias Marinhos –
              MSDIWG
            </a>{' '}
            promovido pela
            <a className={classes.link} href={ohi}>
              {' '}
              Organização Hidrográfica Internacional – OHI
            </a>
            .
          </Typography>
          <Typography className={classes.subTitle} align='center' variant='h6'>
            Plano de Ação
          </Typography>
          <Typography align='left' variant='body1'>
            O planejamento das ações de implantação da IDEM-DHN está sob
            coordenação do Centro de Hidrografia da Marinha. A catalogação e
            disponibilização dos dados serão realizados de forma continuada e em
            prioridade ao atendimento aos objetivos elencados acima.
          </Typography>
        </Grid>
        <Grid className={classes.imgContainer} item md={6} sm={12}>
          <img alt='' className={classes.img} src={batimetria} />
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
};

export default Idem;
