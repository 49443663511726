import React from 'react';
import { Typography, makeStyles, Box, Button, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    info: {
        margin: 0,
    },
    values: {
        margin: 0,
        fontSize: 10
    },
    btn: {
        marginRight: theme.spacing(2)
    },
    btnGroup: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center'
    }
}));

const PNBoiaShowFeatures = props => {
    const classes = useStyles();

    return (
        <div className={classes.info}>
            <Typography display="block" variant={"caption"}><b>Nome: </b>{props.values.nome}</Typography>
            <Typography display="block" variant={"caption"}><b>Modelo: </b>{props.values.modelo_boia}</Typography>
            <Typography display="block" variant={"caption"}><b>Latitude: </b>{props.values.latitude}</Typography>
            <Typography display="block" variant={"caption"}><b>Longitude: </b>{props.values.longitude}</Typography>
            <Typography display="block" variant={"caption"}><b>Metarea: </b>{props.values.secao_metarea}</Typography>
            <Typography display="block" variant={"caption"}><b>Data Lançamento: </b>{props.values.data_lancamento}</Typography>
            <Typography display="block" variant={"caption"}><b>Última Atualização: </b>{props.values.ultimo_dado}</Typography>
            {props.values.ativa && (
                <>
                    <Typography display="block" variant={"caption"}><b>Direção Vento (°): </b>{props.values.dir_vento_1}</Typography>
                    <Typography display="block" variant={"caption"}><b>Velocidade Vento (m/s): </b>{props.values.vel_vento_1}</Typography>
                    <Typography display="block" variant={"caption"}><b>Direção Onda (°): </b>{props.values.dir_onda1}</Typography>
                    <Typography display="block" variant={"caption"}><b>Direção Espalhamento Onda (°): </b>{props.values.dir_espalhamento_onda1}</Typography>
                    <Typography display="block" variant={"caption"}><b>Altura Significativa de Onda (m): </b>{props.values.hs_1}</Typography>
                    <Typography display="block" variant={"caption"}><b>Período de Pico (s): </b>{props.values.tp_1}</Typography>
                </>
            )}
            <Box className={classes.btnGroup}>
                {props.values.metadata && (<Button target="_blank" href={props.values.metadata} className={classes.btn} size="small" variant="outlined" color="primary">Metadados</Button>)}
                <Button target="_blank" href={props.values.link_site} variant="outlined" color="primary">Informações</Button>
            </Box>
            {/* </Stack> */}

        </div >
    )
}

export default PNBoiaShowFeatures;