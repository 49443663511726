import React from 'react'
import { Container, Typography, makeStyles } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';


const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',

    },
    img: {
        width: '100%',
        objectFit: 'cover',
        '&:hover': {
            cursor:'pointer'
        },

    },
    carousel: {
        maxWidth: '960px',
        marginBottom: theme.spacing(3)
    }
}));

function Item(props) {
    const classes = useStyles();
    const handleClick = (link) => {
        window.open(link, '_blank');
    }
    return (
        <Container className={classes.container}>
            <Typography variant='h4'>{props.item.name}</Typography>
            <Typography variant='paragraph'>{props.item.description}</Typography>
            <img onClick={() => handleClick(props.item.link)} alt="" className={classes.img} src={props.item.img}></img>
        </Container>
    )
}

const ImageSlider = (props) => {
    const classes = useStyles();
    return (
        <div>
            <Container className={classes.carousel}>
                <Carousel >
                    {
                        props.items.map((item, i) => <Item key={i} item={item} />)
                    }
                </Carousel>
            </Container>

        </div>
    )
}

export default ImageSlider;
