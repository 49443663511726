import React, { Component, useEffect, useState, useRef } from 'react';
import { Marker, TileLayer, useMap, WMSTileLayer } from 'react-leaflet';
import * as WMS from 'leaflet.wms';
import * as L from 'leaflet';
import $ from 'jquery';
import ShowFeaturesLayer from '../pnboia/ShowFeaturesLayer';
import LayerCaption from './ShowFeaturesLegendLayer';
import ReactDOMServer from 'react-dom/server';
import ShowFeaturesLegendLayer from './ShowFeaturesLegendLayer';
import { render } from '@testing-library/react';

let layerName, layerLegendURL;
let renderLegend = [];
// let renderLegend, objRetorno = [{}]

const AvisoMauTempoLayer = (props) => {
  const map = useMap();
  const {
    url,
    layers,
    checked,
    componentLegend,
    setComponentLegend,
    setIsAvisoMauTempoChecked,
  } = props;

  const filterExpression =
    "tipo_aviso IN ('VENTO FORTE', 'VENTO MUITO FORTE', 'VENTO FORTE/MUITO FORTE', 'VENTO DURO', 'VENTO MUITO DURO', 'VENTO FORTE/DURO', 'VENTO FORTE/MUITO DURO', 'VENTO MUITO FORTE/DURO', 'VENTO DURO/MUITO DURO', 'VENTO MUITO FORTE/MUITO DURO', 'VENTO TEMPESTUOSO', 'VENTO DURO/TEMPESTUOSO', 'VENTO MUITO DURO/TEMPESTUOSO', 'FURACAO', 'MAR GROSSO', 'MAR MUITO GROSSO', 'MAR GROSSO/MUITO GROSSO', 'MAR ALTO', 'MAR MUITO GROSSO/ALTO', 'MAR GROSSO ALTO', 'MAR MUITO ALTO', 'MAR MUITO GROSSO/MUITO ALTO', 'FENOMENAL', 'RESSACA', 'BAIXA VISIBILIDADE', 'ESPECIAL', '')";

  const options = {
    format: 'image/png',
    transparent: 'true',
    info_format: 'application/json',
    layers: layers,
    cql_filter: filterExpression,
  };

  checked ? setIsAvisoMauTempoChecked(true) : setIsAvisoMauTempoChecked(false);

  // const [renderLegend, setRenderLegend] = useState([])

  const apresentarNoLog = (param) => {
    Object.entries(param).map(([key, value]) => {
      //    console.log(`KEY -> ${key} ----- VALUE -> ${value}`);
    });
  };

  L.TileLayer.BetterWMS = L.TileLayer.WMS.extend({
    onAdd: function (map) {
      // Triggered when the layer is added to a map.
      //   Register a click listener, then do all the upstream WMS things
      L.TileLayer.WMS.prototype.onAdd.call(this, map);
      this.getLegendGraphicInfo(true);
      map.on('click', this.callFeatures, this);
    },

    onRemove: function (map) {
      // Triggered when the layer is removed from a map.
      //   Unregister a click listener, then do all the upstream WMS things
      L.TileLayer.WMS.prototype.onRemove.call(this, map);
      this.getLegendGraphicInfo(false);
      map.off('click', this.callFeatures, this);
    },

    getFeatureInfo: function (evt) {
      // Make an AJAX request to the server and hope for the best
      var url = this.getFeatureInfoUrl(evt.latlng),
        showResults = L.Util.bind(this.showGetFeatureInfo, this);
      $.ajax({
        url: url,
        success: function (data, status, xhr) {
          var err = typeof data === 'string' ? null : data;
          showResults(err, evt.latlng, data);
        },
        error: function (xhr, status, error) {
          showResults(error);
        },
      });
    },

    getFeatureInfoUrl: function (latlng) {
      // Construct a GetFeatureInfo request URL given a point

      try {
        var point = this._map.latLngToContainerPoint(
            latlng,
            this._map.getZoom()
          ),
          size = this._map.getSize(),
          params = {
            request: 'GetFeatureInfo',
            service: 'WMS',
            srs: 'EPSG:4326',
            styles: this.wmsParams.styles,
            // styles: wms.wmsParams.styles,
            transparent: this.wmsParams.transparent,
            // transparent: wms.wmsParams.transparent,
            version: this.wmsParams.version,
            // version: wms.wmsParams.version,
            format: this.wmsParams.format,
            // format: wms.wmsParams.format,
            bbox: this._map.getBounds().toBBoxString(),
            // bbox: wms._map.getBounds().toBBoxString(),
            height: size.y,
            width: size.x,
            layers: this.wmsParams.layers,
            cql_filter: this.wmsParams.cql_filter,
            // layers: wms.wmsParams.layers,
            query_layers: this.wmsParams.layers,
            // query_layers: wms.wmsParams.layers,
            // info_format: 'text/html'
            info_format: 'application/json',
          };

        params[params.version === '1.3.0' ? 'i' : 'x'] = point.x;
        params[params.version === '1.3.0' ? 'j' : 'y'] = point.y;
      } catch (error) {
        //  console.log(`[HandleException] getFeatureInfoUrl ===>${error}<===`);
      }

      return this._url + L.Util.getParamString(params, this._url, true);
    },

    // showGetFeatureInfo: function (content, latlng, err) {
    showGetFeatureInfo: function (content, latLong, err) {
      function createPopup() {
        let contents;
        if (Object.keys(content.features[0].properties).length > 0) {
          let areaAviso = content.features[0].properties.area_aviso;
          let areaEntre = content.features[0].properties.area_entre;
          let complemento = content.features[0].properties.complemento;
          let emissaoHora = content.features[0].properties.emissao_hora;
          let emissaoData = content.features[0].properties.emissao_data;
          let inicioHora = content.features[0].properties.inicio_hora;
          let inicioData = content.features[0].properties.inicio_data;
          let validadeHora = content.features[0].properties.validade_hora;
          let validadeData = content.features[0].properties.validade_data;
          let caracteristica = content.features[0].properties.caracteristica;
          let direcaoCaracteristica =
            content.features[0].properties.direcao_caracteristica;
          let texto = content.features[0].properties.texto;
          let tipoAviso = content.features[0].properties.tipo_aviso;
          let numeroAviso = content.features[0].properties.numero_aviso;

          if (emissaoData.charAt(emissaoData.length - 1) === 'z' || 'Z') {
            emissaoData = emissaoData.slice(0, -1);
          }
          if (inicioData !== null) {
            if (inicioData.charAt(inicioData.length - 1) === 'z' || 'Z') {
              inicioData = inicioData.slice(0, -1);
            }
          }
          if (validadeData.charAt(validadeData.length - 1) === 'z' || 'Z') {
            validadeData = validadeData.slice(0, -1);
          }

          let aPartirDe = '';
          let pointAPartirDe = '';
          let pointCaracteristica = '';
          let dirCarac = '';
          let pointTextFinal = '.';
          let newEmissaoData = getFormattedDate(emissaoData);

          function getDayOfWek(date) {
            let [year, month, day] = date.split('-');
            const dayOfWeek = new Date(+year, +month - 1, +day).getDay();

            return isNaN(dayOfWeek)
              ? null
              : ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'][dayOfWeek];
          }

          function getDay(date) {
            if (date) {
              let [year, month, day] = date.split('-');
              return day;
            } else {
              return '';
            }
          }

          function getFormattedDate(date) {
            if (date) {
              let [year, month, day] = date.split('-');
              return `${day}/${month}/${year}`;
            } else {
              return '';
            }
          }

          let area = `${areaAviso} ENTRE ${areaEntre}`;
          //   ${convertCoords.latLngToPoints(layer.feature)}`;

          if (inicioData) {
            aPartirDe = `A PARTIR DE ${getDay(inicioData)}${inicioHora}.`;
            pointAPartirDe = '';
          } else {
            area = area + '.';
          }

          if (direcaoCaracteristica !== null && texto === '') {
            pointTextFinal = '.';
            if (isLastCharacterAPoint(texto)) {
              pointTextFinal = '';
            }
          }
          if (texto === '') {
            pointTextFinal = '';
          }
          if (texto !== '' && isLastCharacterAPoint(texto)) {
            pointTextFinal = '';
          }

          if (tipoAviso === 'BAIXA VISIBILIDADE') {
            if (texto === '') {
              pointCaracteristica = '.';
            } else {
              pointCaracteristica = '';
            }
          } else {
            dirCarac = direcaoCaracteristica;
            if (texto === '') {
              dirCarac = dirCarac + '.';
            }
          }

          function isLastCharacterAPoint(string) {
            if (string.length === 0) {
              return true;
            }

            var lastCharacter = string.charAt(string.length - 1);
            return lastCharacter === '.';
          }

          let pointComplemento = '';
          isLastCharacterAPoint(complemento)
            ? (pointComplemento = '')
            : (pointComplemento = '.');

          if (isLastCharacterAPoint(areaEntre)) {
            area = area.replace(/.$/, '');
          }

          let tipo = '';
          tipoAviso === 'ESPECIAL'
            ? (tipo = `AVISO ESPECIAL`)
            : (tipo = `AVISO DE ${tipoAviso}`);

          contents = `<div class='tooltip-info'> 
          <h4 style="text-align:center">AVISO NR ${numeroAviso} </h4> 
          <ul style="list-style:none;padding:0"> 
          <li>${tipo} </li>
          <li>EMITIDO ÀS ${emissaoHora} - ${getDayOfWek(
            emissaoData
          )} - ${newEmissaoData} </li>
          <li>${area} ${aPartirDe} ${caracteristica}${pointCaracteristica} ${dirCarac} ${texto}${pointTextFinal}</li>
          <li>VÁLIDO ATÉ ${getDay(validadeData)}${validadeHora}. </li>
          <li>${complemento}${pointComplemento}</li>
          </ul>
          </div>`;
        } else {
          contents = `<div class='tooltip-info-empty'><h4> Não há aviso cadastrado até o momento. </h4> </div>`;
        }
        return `${contents}`;
      }

      let html;

      try {
        // if (content) {
        //    if (content.features[0].length > 0) {
        html = createPopup();

        L.popup({ maxWidth: 800 })
          .setLatLng(latLong)
          .setContent(html)
          .openOn(this._map);
        //   }
        //   }
      } catch (error) {}
    },

    getLegendGraphicInfo: function (isRemove) {
      try {
        // Verify if this is an remove or an add. The value is passed by param in the call
        if (isRemove) {
          // let evt = {"latlng" : [-50, -60]}
          var url = this.getLegendGraphicInfoUrl();
          var showLegendResults = L.Util.bind(
            this.showGetLegendGraphicInfo,
            this
          );

          //Retrieve the first value part of a search ex: "mare:estacoes_desativadas"
          layerName = wms.wmsParams.layers;
          layerLegendURL = url.replace(/["']/g, '');

          // renderLegend = ({ ...renderLegend, [layerName]: layerLegendURL })
          // renderLegend = { ...renderLegend, [layerName]: layerLegendURL };
          //   renderLegend.push({ [layerName]: layerLegendURL });
          renderLegend = [...renderLegend, { [layerName]: layerLegendURL }];

          $.ajax({
            url: url,
            success: function (data, status, xhr) {
              // var err = typeof data === 'string' ? null : data;
              var err = typeof data !== 'string' ? null : data;
              // showLegendResults(err, evt.latlng, data);
              showLegendResults(err, data);
            },
            error: function (xhr, status, error) {
              showLegendResults(error);
            },
          });
        } else {
          const newArray = renderLegend.filter(
            (layer) => Object.keys(layer)[0] !== layers
          );

          renderLegend = [...newArray];
        }
        setComponentLegend(renderLegend);
      } catch (error) {
        //    console.log(`[LINHA 187 - GetFeatureInfoLayer] Erro para recuperar informação do serviço geoserver => [ERROR] ${error}`);
      }
    },

    // getLegendGraphicInfoUrl: function (latlng) {
    getLegendGraphicInfoUrl: function () {
      const legendParams = {
        request: 'GetLegendGraphic',
        service: 'WMS',
        version: wms.wmsParams.version,
        format: 'image/png', // You can change the format here based on your preference
        layer: wms.wmsParams.layers,
        legend_options:
          'legend_options=fontName:fontAntiAliasing:true;fontSize:10',
      };

      return wms._url + L.Util.getParamString(legendParams, wms._url, true);
    },

    // New function to show the legend graphic in a popup
    // showGetLegendGraphicInfo: function (content, latlng, err) {
    showGetLegendGraphicInfo: function (content, err) {
      let html;
      if (content.length > 0) {
        html = ReactDOMServer.renderToStaticMarkup(
          <ShowFeaturesLegendLayer render={renderLegend} />
        ); // need to be a component

        setComponentLegend(renderLegend); // ==================> Return obj with pair keys [layer:url_Img]
      }
    },

    callFeatures: function (evt) {
      this.getFeatureInfo(evt);
      // this.getLegendGraphicInfo(evt)
    },
  });

  const beterWms = function (url, options) {
    return new L.TileLayer.BetterWMS(url, options);
  };

  const [wms, setWms] = useState(beterWms(url, options));

  if (props.checked) {
    wms.addTo(map);
  } else {
    wms.setParams({ cql_filter: filterExpression });
    map.removeLayer(wms); //original
    // map.remove(wms)
  }

  // return renderLegend
  return null;
};

export default AvisoMauTempoLayer;
