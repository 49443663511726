import React, { Component, useEffect, useState, useRef } from 'react';
import { Marker, TileLayer, useMap, WMSTileLayer } from 'react-leaflet';
import * as WMS from 'leaflet.wms';
import * as L from 'leaflet';
import $ from 'jquery';
import ShowFeaturesLayer from '../pnboia/ShowFeaturesLayer';
import LayerCaption from './ShowFeaturesLegendLayer';
import ReactDOMServer from 'react-dom/server';
import ShowFeaturesLegendLayer from './ShowFeaturesLegendLayer';
import { render } from '@testing-library/react';

let layerName, layerLegendURL;
let renderLegend = [];
// let renderLegend, objRetorno = [{}]

const GetLegendInfoLayer = (props) => {
  const map = useMap();
  const { url, layers, checked, componentLegend, setComponentLegend } = props;

  const options = {
    format: 'image/png',
    transparent: 'true',
    info_format: 'application/json',
    layers: layers,
    //  cql_filter: layers === 'smm:avisos_teste' ? filterExpression : '',
  };

  // const [renderLegend, setRenderLegend] = useState([])

  const apresentarNoLog = (param) => {
    Object.entries(param).map(([key, value]) => {
      //    console.log(`KEY -> ${key} ----- VALUE -> ${value}`);
    });
  };

  L.TileLayer.BetterWMS = L.TileLayer.WMS.extend({
    onAdd: function (map) {
      // Triggered when the layer is added to a map.
      //   Register a click listener, then do all the upstream WMS things
      L.TileLayer.WMS.prototype.onAdd.call(this, map);
      this.getLegendGraphicInfo(true);
      map.on('click', this.callFeatures, this);
    },

    onRemove: function (map) {
      // Triggered when the layer is removed from a map.
      //   Unregister a click listener, then do all the upstream WMS things
      L.TileLayer.WMS.prototype.onRemove.call(this, map);
      this.getLegendGraphicInfo(false);
      map.off('click', this.callFeatures, this);
    },

    getFeatureInfo: function (evt) {
      // Make an AJAX request to the server and hope for the best
      var url = this.getFeatureInfoUrl(evt.latlng),
        showResults = L.Util.bind(this.showGetFeatureInfo, this);
      $.ajax({
        url: url,
        success: function (data, status, xhr) {
          var err = typeof data === 'string' ? null : data;
          showResults(err, evt.latlng, data);
        },
        error: function (xhr, status, error) {
          showResults(error);
        },
      });
    },

    getFeatureInfoUrl: function (latlng) {
      // Construct a GetFeatureInfo request URL given a point

      try {
        var point = this._map.latLngToContainerPoint(
            latlng,
            this._map.getZoom()
          ),
          size = this._map.getSize(),
          params = {
            request: 'GetFeatureInfo',
            service: 'WMS',
            srs: 'EPSG:4326',
            styles: this.wmsParams.styles,
            // styles: wms.wmsParams.styles,
            transparent: this.wmsParams.transparent,
            // transparent: wms.wmsParams.transparent,
            version: this.wmsParams.version,
            // version: wms.wmsParams.version,
            format: this.wmsParams.format,
            // format: wms.wmsParams.format,
            bbox: this._map.getBounds().toBBoxString(),
            // bbox: wms._map.getBounds().toBBoxString(),
            height: size.y,
            width: size.x,
            layers: this.wmsParams.layers,
            // layers: wms.wmsParams.layers,
            query_layers: this.wmsParams.layers,
            // query_layers: wms.wmsParams.layers,
            // info_format: 'text/html'
            info_format: 'application/json',
          };

        params[params.version === '1.3.0' ? 'i' : 'x'] = point.x;
        params[params.version === '1.3.0' ? 'j' : 'y'] = point.y;
      } catch (error) {
        //  console.log(`[HandleException] getFeatureInfoUrl ===>${error}<===`);
      }

      return this._url + L.Util.getParamString(params, this._url, true);
    },

    // showGetFeatureInfo: function (content, latlng, err) {
    showGetFeatureInfo: function (content, latLong, err) {
      // showGetFeatureInfo: function (err, latlng, content) {
      // if (err) { console.log("error",err); return; } // do nothing if there's an error
      // console.log(err);
      // Otherwise show the content in a popup, or something.
      let values = {};
      let html;

      try {
        if (content) {
          if (content.features.length > 0) {
            Object.keys(content.features[0].properties).map((item) => {
              if (content.features[0].properties[item]) {
                values[item] = content.features[0].properties[item];
              }
            });

            html = ReactDOMServer.renderToStaticMarkup(
              <ShowFeaturesLayer values={values} />
            ); // precisa ser um component

            L.popup({ maxWidth: 800 })
              .setLatLng(latLong)
              .setContent(html.toString())
              .openOn(this._map);
          }
          // else {
          //     console.log('sem dados');
          //     // this._map.flyTo(latlng, this._map.getZoom() + 1);
          // }
        }
      } catch (error) {
        //   console.log(`[HandleException] showGetFeatureInfo ===>${error}<===`);
      }
    },

    /**
     *** @TODO: Capturing legend features.
     **/
    getLegendGraphicInfo: function (isRemove) {
      /**[10/08/2023] STUBS @ Diego Ferreira
       * [Begin]
       */

      try {
        // Verify if this is an remove or an add. The value is passed by param in the call
        if (isRemove) {
          // let evt = {"latlng" : [-50, -60]}
          var url = this.getLegendGraphicInfoUrl();
          var showLegendResults = L.Util.bind(
            this.showGetLegendGraphicInfo,
            this
          );

          //Retrieve the first value part of a search ex: "mare:estacoes_desativadas"
          layerName = wms.wmsParams.layers;
          layerLegendURL = url.replace(/["']/g, '');

          // renderLegend = ({ ...renderLegend, [layerName]: layerLegendURL })
          // renderLegend = { ...renderLegend, [layerName]: layerLegendURL };
          //   renderLegend.push({ [layerName]: layerLegendURL });
          renderLegend = [...renderLegend, { [layerName]: layerLegendURL }];

          $.ajax({
            url: url,
            success: function (data, status, xhr) {
              // var err = typeof data === 'string' ? null : data;
              var err = typeof data !== 'string' ? null : data;
              // showLegendResults(err, evt.latlng, data);
              showLegendResults(err, data);
            },
            error: function (xhr, status, error) {
              showLegendResults(error);
            },
          });
        } else {
          const newArray = renderLegend.filter(
            (layer) => Object.keys(layer)[0] !== layers
          );

          renderLegend = [...newArray];
        }
        setComponentLegend(renderLegend);
      } catch (error) {
        //    console.log(`[LINHA 187 - GetFeatureInfoLayer] Erro para recuperar informação do serviço geoserver => [ERROR] ${error}`);
      }
    },

    // getLegendGraphicInfoUrl: function (latlng) {
    getLegendGraphicInfoUrl: function () {
      const legendParams = {
        request: 'GetLegendGraphic',
        service: 'WMS',
        version: wms.wmsParams.version,
        format: 'image/png', // You can change the format here based on your preference
        layer: wms.wmsParams.layers,
      };

      return wms._url + L.Util.getParamString(legendParams, wms._url, true);
    },

    // New function to show the legend graphic in a popup
    // showGetLegendGraphicInfo: function (content, latlng, err) {
    showGetLegendGraphicInfo: function (content, err) {
      let html;
      if (content.length > 0) {
        html = ReactDOMServer.renderToStaticMarkup(
          <ShowFeaturesLegendLayer render={renderLegend} />
        ); // need to be a component

        setComponentLegend(renderLegend); // ==================> Return obj with pair keys [layer:url_Img]
      }
    },

    callFeatures: function (evt) {
      this.getFeatureInfo(evt);
      // this.getLegendGraphicInfo(evt)
    },
  });

  const beterWms = function (url, options) {
    return new L.TileLayer.BetterWMS(url, options);
  };

  const [wms, setWms] = useState(beterWms(url, options));

  if (props.checked) {
    wms.addTo(map);
  } else {
    map.removeLayer(wms); //original
    // map.remove(wms)
  }

  // return renderLegend
  return null;
};

export default GetLegendInfoLayer;
