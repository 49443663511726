//import 'dotenv/config';
import React, { useEffect, useState, useRef } from 'react';
import {
  CircleMarker,
  FeatureGroup,
  LayersControl,
  LayerGroup,
  MapContainer,
  Marker,
  Popup,
  Rectangle,
  TileLayer,
  useMapEvents,
  WMSTileLayer,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './Map.css';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonGroup,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
  withStyles,
  Tooltip,
  createTheme,
  ThemeProvider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import XMLParser from 'react-xml-parser';
import AddIcon from '@material-ui/icons/Add';
import { Add, ExpandMore } from '@material-ui/icons';
import { withLeaflet, useMap } from 'react-leaflet';
import * as WMS from 'leaflet.wms';
import AvisoRadioLayer from '../components/layers/AvisoRadioLayer';
import CartaSinoticaLayer from '../components/layers/CartaSinoticaLayer';
import GetFeatureInfoLayer from '../components/layers/GetFeatureInfoLayer';
import GetLegendInfoLayer from '../components/layers/GetLegendInfoLayer';
import titles from '../conf/keys.json';
import { layer } from 'leaflet.wms';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { blue } from '@material-ui/core/colors';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PNBoiaLayer from '../components/layers/PNBoiaLayer';
import LatLong from '../components/LatLong';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import LayersIcon from '@material-ui/icons/Layers';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import { PopUp } from '../components/utils/PopUp';
import LayerCaption from '../components/layers/ShowFeaturesLegendLayer';
import { Popper } from '@material-ui/core';
import FarolLayer from '../components/layers/FarolLayer';
import ShowFeaturesLegendLayer from '../components/layers/ShowFeaturesLegendLayer';
//import { LayerGroup, map } from 'leaflet';
import MeasureControl from '../components/controls/MeasureControl';
import LayerManager from '../components/LayerManager';
import LayerOperations from '../components/LayerOperations';
import ShowMetadataDialog from '../components/dialogs/ShowMetadataDialog';
import ShowWmsUrl from '../components/dialogs/ShowWmsUrl';
import Layers from '@material-ui/icons/Layers';
import CaptionDrawer from '../components/controls/CaptionDrawer';
import LayerManagerDrawer from '../components/LayerManagerDrawer';
import AvisoMauTempoLayer from '../components/layers/AvisoMauTempoLayer';
import AvisosMauTempoControl from '../components/controls/AvisosMauTempoControl';
//import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';

// import farolRadioLayer from "../components/layers/FaroisLayer";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    top: theme.spacing(14),
    left: theme.spacing(4),
    zIndex: 2,
    // bottom: theme.spacing(2),
    // right: theme.spacing(2),
  },
  layerCaption: {
    position: 'fixed',
    top: theme.spacing(24),
    left: theme.spacing(4),
    zIndex: 2,
    // bottom: theme.spacing(2),
    // right: theme.spacing(2),
  },
  farolDrawer: {
    position: 'fixed',
    top: theme.spacing(34),
    left: theme.spacing(4),
    zIndex: 2,
    // bottom: theme.spacing(2),
    // right: theme.spacing(2),
  },
  mostrarSlider: {
    position: 'fixed',
    top: theme.spacing(44),
    left: theme.spacing(4),
    zIndex: 2,
  },
  avisosControl: {
    position: 'fixed',
    top: theme.spacing(54),
    left: theme.spacing(4),
    zIndex: 2,
    background: '#FFF',
    transition: '0.3s',
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#FFF',
    },
  },
  avisosControlSinoticaOpened: {
    position: 'fixed',
    top: theme.spacing(64),
    left: theme.spacing(4),
    zIndex: 2,
    background: '#FFF',
    transition: '0.3s',
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#FFF',
    },
  },
  // measureControl: {
  //   position: 'fixed',
  //   top: theme.spacing(54),
  //   left: theme.spacing(4),
  //   zIndex: 2000,
  //   // bottom: theme.spacing(2),
  //   // right: theme.spacing(2),
  // },
  // unitControl: {
  //   width: '24px',
  //   height: '24px',
  //   position: 'fixed',
  //   top: theme.spacing(54),
  //   left: theme.spacing(1),
  //   zIndex: 1999,
  //   minHeight: '24px',
  // bottom: theme.spacing(2),
  // right: theme.spacing(2),
  // },
  drawerPaper: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  root: {
    width: '100%',
  },
  layerName: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  layerItem: {
    paddingTop: '0',
    paddingBottom: '0',
  },
  contenierPam: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  latlong: {
    position: 'fixed',
    top: '94%',
    left: '93%',
    background: '#FFF',
    padding: theme.spacing(0.5),
    zIndex: 2,
  },
  listDrawer: {
    // marginTop: 32
    overflowY: 'scroll',
  },
  progress: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    top: '50%',
    left: '0',
    right: '0',
    textAlign: 'center',
    zIndex: 1000,
  },
  captionBtn: {
    position: 'fixed',
    top: theme.spacing(34),
    left: theme.spacing(4),
    zIndex: 2,
  },
  layerManagerTitle: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  layerManagerDrawer: {
    margin: '0',
    width: { xs: '100vw', sm: '350px', opacity: '0' },
  },
  button: {
    color: 'white',
    marginTop: '10px',
    background: '#3f51b5',
    '&:hover': {
      color: 'white',
      background: '#303f9f',
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    },
  },
}));

const Map = (props) => {
  // const { openStatus, setOpenStatus, loadingData, setLoadingData } = props;
  const classes = useStyles();
  const center = [-17.7757265, -50.0773024];
  const [map, setMap] = useState(null);
  const [open, setOpen] = useState(false);
  const [layers, setLayers] = useState([]);
  const [layersLevel, setLayersLevel] = useState(0); // control layer index level
  const [types, setTypes] = useState({});
  const [position, setPosition] = useState(center);
  const [loading, setLoading] = useState(true);
  const [selectedLayers, setSelectedLayers] = useState([]);
  const [activeLayers, setActiveLayers] = useState([]);
  const [isLayerManagerOpen, setIsLayerManagerOpen] = useState(false);
  const [isShowMetadataDialogOpen, setIsShowMetadataDialogOpen] =
    useState(false);
  const [metadataInfo, setMetadataInfo] = useState({
    currentTitle: '',
    currentAbstract: '',
  });
  const [wmsUrlInfo, setWmsUrlInfo] = useState({
    version: '',
    request: '',
    layers: '',
    styles: '',
    bbox: '',
    width: '',
    height: '',
    tiled: 'true',
    srs: '',
    format: '',
    transparent: '',
  });
  const [isShowWmsUrlOpen, setIsShowWmsUrlOpen] = useState(false);
  const [currentTitle, setCurrentTitle] = useState('');

  // [INI] Diego Ferreira - Stubs
  const [isCartaSinoticaButtonOpened, setIsCartaSinoticaButtonOpened] =
    useState(false);
  const [isAvisMauTempoChecked, setIsAvisoMauTempoChecked] = useState(false);
  const [farolState, setFarolState] = useState(false);
  const [showCaptionDrawer, setShowCaptionDrawer] = useState(false);
  const [componentLegend, setComponentLegend] = useState([]);
  const [checkedFarol, setCheckedFarol] = useState(false);
  const [showAvisoMauTempoControl, setShowAvisoMauTempoControl] =
    useState(false);

  const handleBtnCaptionDrawer = () => {
    setShowCaptionDrawer(!showCaptionDrawer);
  };

  // const api = axios.create({
  //   baseURL:
  //     'https://idem.dhn.mar.mil.br/geoserver/ows?service=WMS&request=GetCapabilities',
  // });
  const api = axios.create({
    baseURL: process.env.REACT_APP_CAPABILITIES_URL,
    //  'https://idem-desenv.chm.mb/geoserver/ows?service=WMS&request=GetCapabilities',
    // withCredentials: false,
  });

  let legendLayer;

  const getCapabilities = () => {
    api
      .get()
      .then((res) => {
        let dict = {};
        const xml = new XMLParser().parseFromString(res.data);
        const layers = xml.getElementsByTagName('Layer');

        layers.splice(0, 1); //retirando elemento Geoserver WMS

        dict['PAM (Previsão Ambiental Marinha)'] = [];

        layers.forEach((layer) => {
          layer.checked = false;
          layer.name = layer.children[1].value;
          layer.layer = layer.children[0].value;

          layer.children.forEach((child) => {
            if (
              child.name === 'BoundingBox' &&
              child.attributes.CRS === 'CRS:84'
            ) {
              layer.bbox = [
                [child.attributes.miny, child.attributes.minx],
                [child.attributes.maxy, child.attributes.maxx],
              ];
            }
            if (child.name === 'Abstract') {
              layer.abstract = child.value;
            }
            if (child.name === 'KeywordList') {
              let keywords = [];
              keywords = child.children.map((item) => item.value);
              layer.keywords = keywords;
            }
          });

          const type = titles[layer.layer.split(':')[0]];

          if (dict[type]) {
            dict[type].push({
              checked: layer.checked,
              name: layer.name,
              layer: layer.layer,
              bbox: layer.bbox,
              abstract: layer.abstract,
              keywords: layer.keywords,
            });
          } else {
            dict[type] = [];
            dict[type].push({
              checked: layer.checked,
              name: layer.name,
              layer: layer.layer,
              bbox: layer.bbox,
              abstract: layer.abstract,
              keywords: layer.keywords,
            });
          }
        });

        dict['Sinais Náuticos'] = [
          {
            checked: false,
            name: titles.sinais_nauticos,
            wmsParams: '',
          },
        ];

        dict['Carta Sinótica'] = [
          {
            checked: false,
            name: titles.carta_sinotica_layer,
          },
        ];
        setLayers(
          Object.keys(dict)
            .sort()
            .reduce((r, k) => ((r[k] = dict[k]), r), {})
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCapabilities();
  }, []);

  useEffect(() => {
    if (map) {
      console.log('map', map);
      let mapLayers = [];
      let newArray = [];

      // check if it is a WMS Layer (and not a Base Layer nor Carta Sinotica)
      map.eachLayer(function (layer) {
        if (layer.hasOwnProperty('wmsParams')) {
          const layerName = layer.wmsParams.layers.split(':')[0];
          if (layerName !== 'carta_sinotica') {
            mapLayers = [...mapLayers, layer];
          }
        }
      });

      function getDifference(array1, array2) {
        const difference = [];

        // Create Sets from both arrays for faster element lookup
        const set1 = new Set(array1);
        const set2 = new Set(array2);

        // Iterate through array1 and add elements to difference if not in array2
        for (const item of set1) {
          if (!set2.has(item)) {
            difference.push(item);
          }
        }

        for (const item of set2) {
          if (!set1.has(item)) {
            difference.push(item);
          }
        }

        return difference;
      }

      if (activeLayers.length === 0) {
        setActiveLayers(mapLayers);
      } else {
        newArray = getDifference(mapLayers, activeLayers);
        if (newArray.length > 0) {
          if (mapLayers.length >= activeLayers.length) {
            setActiveLayers((prev) => [newArray[0], ...prev]);
          } else {
            const popArray = activeLayers.filter(
              (activeLayer) => activeLayer !== newArray[0]
            );
            setActiveLayers(popArray);
          }
        }
      }
    }
  }, [layers]);

  //Handle drawer of main menu
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCheckChange = (value, key) => {
    const newLayers = {};
    Object.assign(newLayers, layers);
    key = value[0] === titles.sinais_nauticos ? 0 : key;
    newLayers[value[0]][key].checked = !newLayers[value[0]][key].checked;

    setLayers(newLayers);
  };

  const handleLayerManagerOpen = () => {
    setIsLayerManagerOpen(true);
  };

  const handleLayerManagerClose = () => {
    setIsLayerManagerOpen(false);
  };

  function reverseOrder() {
    setIsLayerManagerOpen(!isLayerManagerOpen);
  }

  function controlLayer() {
    legendLayer.forEach((lay) => {
      lay.name = lay.children[1].value;
      lay.layer = lay.children[0].value;
    });
  }

  useEffect(() => {
    if (map !== null) {
      map.invalidateSize();
    }
  }, [map]);

  return (
    <div>
      {loading ? (
        <CircularProgress
          thickness={10}
          size={40}
          className={classes.progress}
        />
      ) : null}

      <CaptionDrawer
        open={showCaptionDrawer}
        setShowCaptionDrawer={setShowCaptionDrawer}
        componentLegend={componentLegend}
        layers={layers}
        handleDrawerOpen={handleDrawerOpen}
        activeLayers={activeLayers}
      />

      <Drawer open={open} onClose={handleDrawerClose}>
        <IconButton
          onClick={handleDrawerClose}
          style={{ borderRadius: 0, height: '64px' }}
        >
          <CloseIcon />
        </IconButton>
        <Divider />

        <List className={classes.listDrawer}>
          {Object.entries(layers).map((value, index) => {
            if (value[0] === 'PAM (Previsão Ambiental Marinha)') {
              return (
                <ListItem key={index}>
                  <div className={classes.root}>
                    <Accordion
                      expand='true'
                      onClick={() => {
                        window.open('https://pam.dhn.mar.mil.br/', '_blank');
                      }}
                    >
                      <AccordionSummary
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                      >
                        <Box className={classes.contenierPam}>
                          <Typography className={classes.heading}>
                            {value[0]}
                          </Typography>
                          <OpenInNewIcon />
                        </Box>
                      </AccordionSummary>
                    </Accordion>
                  </div>
                </ListItem>
              );
            }
            if (value[0] === titles.avisos) {
              return (
                <ListItem key={index}>
                  <div className={classes.root}>
                    <Accordion
                      expand={false}
                      onClick={() => {
                        handleCheckChange(value, index);
                      }}
                    >
                      <AccordionSummary
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                      >
                        <Box className={classes.contenierPam}>
                          <Typography className={classes.heading}>
                            {titles.avisos}
                          </Typography>
                          {/* <OpenInNewIcon /> */}
                        </Box>
                      </AccordionSummary>
                    </Accordion>
                  </div>
                </ListItem>
              );
            }
            if (value[0] === titles.sinais_nauticos) {
              return (
                <ListItem key={index}>
                  <div className={classes.root}>
                    <Accordion expand={false}>
                      <AccordionSummary
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                      >
                        <Box
                          className={classes.contenierPam}
                          onClick={() => {
                            handleCheckChange(value, index);
                            setCheckedFarol(!checkedFarol);
                          }}
                        >
                          <Typography
                            className={classes.heading}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {titles.sinais_nauticos}
                          </Typography>
                          <Typography className={classes.heading}>
                            <Checkbox checked={checkedFarol} />
                          </Typography>
                        </Box>
                      </AccordionSummary>
                    </Accordion>
                  </div>
                </ListItem>
              );
            }

            return (
              <ListItem key={index}>
                <div className={classes.root}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls='panel2a-content'
                      id='panel2a-header'
                    >
                      <Typography className={classes.heading}>
                        {value[0]}
                      </Typography>
                    </AccordionSummary>
                    {/* <ListItemText primary={value[0]} /> */}
                    <List>
                      {value[1].map((layer, key) => {
                        return (
                          <ListItem className={classes.layerItem} key={key}>
                            <AccordionDetails className={classes.layerItem}>
                              <Checkbox
                                checked={layer.checked}
                                onChange={() => handleCheckChange(value, key)}
                                color='primary'
                              />
                              <ListItemText
                                className={classes.layerName}
                                primary={layer.name}
                              />
                            </AccordionDetails>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Accordion>
                </div>
              </ListItem>
            );
          })}
        </List>
      </Drawer>

      {/* {legendControl ? */}

      <Tooltip title='Adicionar Camada' placement='right'>
        <Fab
          color='primary'
          aria-label='add'
          onClick={handleDrawerOpen}
          className={classes.fab}
        >
          <AddIcon />
        </Fab>
      </Tooltip>

      {/* // LEGEND BUTTON */}

      <Tooltip title='Gerenciar Camadas' placement='right'>
        <Fab
          color='primary'
          aria-label='add'
          open={true}
          onClick={reverseOrder}
          className={classes.layerCaption}
        >
          <LayersIcon />
        </Fab>
      </Tooltip>

      <Tooltip title='Exibir Legendas' placement='right'>
        <Fab
          color='primary'
          aria-label='add'
          open={true}
          onClick={handleBtnCaptionDrawer}
          className={classes.captionBtn}
        >
          <SubtitlesIcon />
        </Fab>
      </Tooltip>

      {isAvisMauTempoChecked ? (
        !showAvisoMauTempoControl ? (
          <Box
            onMouseOver={() => setShowAvisoMauTempoControl(true)}
            //  onMouseLeave={() => setShowAvisoMauTempoControl(false)}
          >
            <Tooltip title='Gerenciar Avisos de Mau Tempo' placement='right'>
              <Fab
                color='primary'
                aria-label='add'
                onClick={handleDrawerOpen}
                className={
                  isCartaSinoticaButtonOpened
                    ? classes.avisosControlSinoticaOpened
                    : classes.avisosControl
                }
              >
                <LayersOutlinedIcon />
              </Fab>
            </Tooltip>
          </Box>
        ) : (
          <Box onMouseLeave={() => setShowAvisoMauTempoControl(false)}>
            <AvisosMauTempoControl
              setShowAvisoMauTempoControl={setShowAvisoMauTempoControl}
              activeLayers={activeLayers}
              isCartaSinoticaButtonOpened={isCartaSinoticaButtonOpened}
            />
          </Box>
        )
      ) : null}

      <ShowMetadataDialog
        metadataInfo={metadataInfo}
        isShowMetadataDialogOpen={isShowMetadataDialogOpen}
        setIsShowMetadataDialogOpen={setIsShowMetadataDialogOpen}
      />

      <ShowWmsUrl
        wmsUrlInfo={wmsUrlInfo}
        isShowWmsUrlOpen={isShowWmsUrlOpen}
        setIsShowWmsUrlOpen={setIsShowWmsUrlOpen}
      />

      <MapContainer
        //    style={{ height: '92vh' }}
        center={center}
        zoom={4}
        scrollWheelZoom={true}
        cursor={true}
        ref={setMap}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />

        <MeasureControl />

        <Drawer open={isLayerManagerOpen} onClose={handleLayerManagerClose}>
          <IconButton
            onClick={handleLayerManagerClose}
            style={{
              borderRadius: 0,
              width: '100%',
              height: '64px',
              padding: '10px 0',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Divider />
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                margin: '0',
                width: { xs: '100vw', sm: '350px' },
                overflowX: 'hidden',
                scrollbarWidth: 'none',
              }}
            >
              {activeLayers.length === 0 ? (
                <Box
                  style={{
                    height: '80vh',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  }}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  <Box
                    style={{
                      height: '80px',
                      width: '100%',
                    }}
                    display='flex'
                    flexDirection='column'
                    justifyContent='space-evenly'
                    alignItems='center'
                  >
                    <Typography variant='h5'>Não há camadas ativas.</Typography>
                    <Button
                      className={classes.button}
                      variant='contained'
                      startIcon={<Add />}
                      onClick={handleDrawerOpen}
                    >
                      ADICIONAR
                    </Button>
                  </Box>
                </Box>
              ) : (
                <LayerManagerDrawer
                  layers={layers}
                  setLayers={setLayers}
                  activeLayers={activeLayers}
                  setActiveLayers={setActiveLayers}
                  metadataInfo={metadataInfo}
                  setMetadataInfo={setMetadataInfo}
                  wmsUrlInfo={wmsUrlInfo}
                  setWmsUrlInfo={setWmsUrlInfo}
                  currentTitle={currentTitle}
                  setCurrentTitle={setCurrentTitle}
                  isShowMetadataDialogOpen={isShowMetadataDialogOpen}
                  setIsShowMetadataDialogOpen={setIsShowMetadataDialogOpen}
                  isShowWmsUrlOpen={isShowWmsUrlOpen}
                  setIsShowWmsUrlOpen={setIsShowWmsUrlOpen}
                />
              )}
            </Box>
          </ThemeProvider>
        </Drawer>

        {Object.entries(layers).map((key, index) => {
          let avisos = key[0] === titles.avisos ? titles.avisos : '';
          let farol =
            key[0] === 'Sinais Náuticos' ? titles.sinais_nauticos : '';

          return key[1].map((layer, key, index) => {
            return (
              <>
                {layer.checked ? (
                  //@TODO : Call a function that setLayer to an array, then put the Legend of it
                  layer.name === avisos ? (
                    <AvisoRadioLayer checked={layer.checked} />
                  ) : layer.name === farol ? (
                    <FarolLayer
                      checked={true}
                      name={titles.sinais_nauticos}
                      farolState={!farolState}
                      activeLayers={activeLayers}
                      setActiveLayers={setActiveLayers}
                    />
                  ) : layer.name === titles.carta_sinotica_layer ? (
                    <CartaSinoticaLayer
                      checked={layer.checked}
                      activeLayers={activeLayers}
                      setIsCartaSinoticaButtonOpened={
                        setIsCartaSinoticaButtonOpened
                      }
                    />
                  ) : layer.name === titles.avisos_teste ? (
                    <AvisoMauTempoLayer
                      checked={layer.checked}
                      layers={layer.layer}
                      url={`${process.env.REACT_APP_IDEM_URL}/geoserver/ows?SERVICE=WMS`}
                      setComponentLegend={setComponentLegend}
                      componentLegend={componentLegend}
                      setIsAvisoMauTempoChecked={setIsAvisoMauTempoChecked}
                    />
                  ) : layer.name ===
                      titles.geodesia_estacao_planialtimetrica_layer ||
                    layer.name === titles.limites_fronteira_NS_Brasil_layer ||
                    layer.name ===
                      titles.limites_jurisdicional_12M_nauticas_layer ||
                    layer.name ===
                      titles.limites_jurisdicional_24M_nauticas_layer ||
                    layer.name ===
                      titles.limites_jurisdicional_200M_nauticas_layer ||
                    layer.name ===
                      titles.limites_exterior_plataforma_estendida_layer ||
                    layer.name === titles.limites_linha_base_layer ||
                    layer.name === titles.leplac_linhas_batimetricas_layer ||
                    layer.name === titles.leplac_linhas_gravimetricas_layer ||
                    layer.name === titles.leplac_linhas_magnetometricas_layer ||
                    layer.name === titles.leplac_linhas_sismicas_layer ||
                    layer.name ===
                      titles.leplac_modelo_digital_elevacao_margem_br_layer ||
                    layer.name ===
                      titles.leplac_modelo_relevo_submarino_layer ||
                    layer.name === titles.geologia_tipo_fundo_layer ||
                    layer.name ===
                      titles.plataforma_continental_mar_territorial_layer ||
                    layer.name ===
                      titles.plataforma_continental_estendida_layer ||
                    layer.name === titles.plataforma_continental_zee_layer ||
                    layer.name ===
                      titles.plataforma_continental_zona_contigua_layer ||
                    layer.name === titles.smm_estacoes_meteorologicas ||
                    layer.name ===
                      titles.mare_estacoes_fluviometricas_ativas_inativas_layer ||
                    layer.name ===
                      titles.mare_estacoes_maregraficas_ativas_inativas_layer ? (
                    <GetLegendInfoLayer
                      checked={layer.checked}
                      layers={layer.layer}
                      url={`${process.env.REACT_APP_IDEM_URL}/geoserver/ows?SERVICE=WMS`}
                      setComponentLegend={setComponentLegend}
                      componentLegend={componentLegend}
                    />
                  ) : layer.name === titles.pnboia_fixas_ativas ||
                    layer.name === titles.pnboia_historicas ||
                    layer.name === 'Boias Fixas' ? (
                    <PNBoiaLayer
                      checked={layer.checked}
                      layers={layer.layer}
                      url={`${process.env.REACT_APP_IDEM_URL}/geoserver/ows?SERVICE=WMS`} //--> original
                    />
                  ) : (
                    <WMSTileLayer
                      checked={layer.checked}
                      url={`${process.env.REACT_APP_IDEM_URL}/geoserver/ows?SERVICE=WMS&`}
                      layers={layer.layer}
                      format='image/png' //original
                      transparent={true} //original
                      zIndex={1}
                      opacity={1}
                      eventHandlers={{
                        load: () => {
                          setLoading(false);
                        },
                        loading: () => {
                          setLoading(true);
                        },
                      }}
                    />
                  )
                ) : layer.name === avisos ? (
                  <AvisoRadioLayer checked={false} />
                ) : layer.name === farol ? (
                  <FarolLayer
                    checked={false}
                    name={titles.sinais_nauticos}
                    farolState={!farolState}
                    activeLayers={activeLayers}
                    setActiveLayers={setActiveLayers}
                    state={true}
                  />
                ) : layer.name === 'Boias Meteoceanográficas Fixas Ativas' ||
                  layer.name === 'Boias Meteoceanográficas Históricas' ||
                  layer.name === 'Boias Fixas' ? (
                  <PNBoiaLayer
                    checked={false}
                    layers={layer.layer}
                    url={`${process.env.REACT_APP_IDEM_URL}/geoserver/ows?SERVICE=WMS`}
                  />
                ) : layer.name === titles.avisos_teste ? (
                  <AvisoMauTempoLayer
                    checked={false}
                    layers={layer.layer}
                    url={`${process.env.REACT_APP_IDEM_URL}/geoserver/ows?SERVICE=WMS`}
                    componentLegend={componentLegend}
                    setComponentLegend={setComponentLegend}
                    setIsAvisoMauTempoChecked={setIsAvisoMauTempoChecked}
                  />
                ) : layer.name ===
                    titles.geodesia_estacao_planialtimetrica_layer ||
                  layer.name === titles.limites_fronteira_NS_Brasil_layer ||
                  layer.name ===
                    titles.limites_jurisdicional_12M_nauticas_layer ||
                  layer.name ===
                    titles.limites_jurisdicional_24M_nauticas_layer ||
                  layer.name ===
                    titles.limites_jurisdicional_200M_nauticas_layer ||
                  layer.name ===
                    titles.limites_exterior_plataforma_estendida_layer ||
                  layer.name === titles.limites_linha_base_layer ||
                  layer.name === titles.leplac_linhas_batimetricas_layer ||
                  layer.name === titles.leplac_linhas_gravimetricas_layer ||
                  layer.name === titles.leplac_linhas_magnetometricas_layer ||
                  layer.name === titles.leplac_linhas_sismicas_layer ||
                  layer.name ===
                    titles.leplac_modelo_digital_elevacao_margem_br_layer ||
                  layer.name === titles.leplac_modelo_relevo_submarino_layer ||
                  layer.name === titles.geologia_tipo_fundo_layer ||
                  layer.name ===
                    titles.plataforma_continental_mar_territorial_layer ||
                  layer.name ===
                    titles.plataforma_continental_estendida_layer ||
                  layer.name === titles.plataforma_continental_zee_layer ||
                  layer.name ===
                    titles.plataforma_continental_zona_contigua_layer ||
                  layer.name === titles.smm_estacoes_meteorologicas ||
                  layer.name ===
                    titles.mare_estacoes_fluviometricas_ativas_inativas_layer ||
                  layer.name ===
                    titles.mare_estacoes_maregraficas_ativas_inativas_layer ||
                  layer.name === titles.avisos_teste ? (
                  <GetLegendInfoLayer
                    checked={false}
                    layers={layer.layer}
                    url={`${process.env.REACT_APP_IDEM_URL}/geoserver/ows?SERVICE=WMS`}
                    componentLegend={componentLegend}
                    setComponentLegend={setComponentLegend}
                  />
                ) : null}
              </>
            );
          });
        })}
        <LatLong />
      </MapContainer>
    </div>
  );
};

export default Map;
