import React from 'react';
import { Typography, makeStyles, Box, Button, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    info: {
        margin: 0,
    },
    values: {
        margin: 0,
        fontSize: 10
    },
    btn: {
        marginRight: theme.spacing(2)
    },
    btnGroup: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center'
    }
}));

const ShowFeaturesLayer = props => {
    const classes = useStyles();

    return (
        <div className={classes.info}>
            {
                Object.keys(props.values).map((key, value) => {
                    return (
                        key != 'LINK' ?
                            (<Typography display="block" variant={"caption"}> <b> {key.toUpperCase()} </b> : {props.values[key]} </Typography>)
                            :
                            <Box className={classes.btnGroup}>
                                <Button target="_blank" href={props.values.LINK} className={classes.btn} size="small" variant="outlined" color="primary">Mais Informações</Button>
                            </Box>
                    )
                })
            }
        </div >
    )
}

export default ShowFeaturesLayer;