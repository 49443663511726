import Footer from './components/Footer';
import NavBar from './components/NavBar';
import Home from './pages/Home';
import Idem from './pages/Idem';
import Map from './pages/Map';
// import { HashRouter as Router, Switch, Route } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Routes,
  Route,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { PopUp } from './components/utils/PopUp';
import About from './components/About';
import { useState } from 'react';
import AvisosMauTempoControl from './components/controls/AvisosMauTempoControl';

const App = () => {
  const [openStatus, setOpenStatus] = useState(true);

  return (
    <div>
      <Router>
        {/* <AvisosMauTempoControl /> */}
        <NavBar />
        {/* <PopUp
          openStatus={openStatus}
          setOpenStatus={setOpenStatus}
          title='Infraestrutura de Dados Espaciais Marinhos - DHN'
          content='Este geoportal não substitui os canais oficiais de comunicação navtex e safetnet. Limites internacionais e espaço marítimo aproximados. Não adequado para navegação.'
        /> */}
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/idem' element={<Idem />} />
          <Route
            exact
            path='/map'
            element={
              <Map openStatus={openStatus} setOpenStatus={setOpenStatus} />
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
