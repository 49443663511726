import {
  Box,
  IconButton,
  Popover,
  Slider,
  ThemeProvider,
  Tooltip,
  Typography,
  createTheme,
  makeStyles,
} from '@material-ui/core';
import {
  Archive,
  Close,
  CloudDownload,
  Info,
  Link,
  TableChart,
  ZoomIn,
} from '@material-ui/icons';
import axios from 'axios';
import XMLParser from 'react-xml-parser';

import { useState, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import ShowMetadataDialog from './dialogs/ShowMetadataDialog';

const useStyles = makeStyles((theme) => ({
  sliderLabel: {
    marginBottom: '4px',
  },
}));

const LayerOperations = ({
  isDragging,
  layerName,
  activeLayer,
  layers,
  draggableEventListener,
  activeLayers,
  setActiveLayers,
  metadataInfo,
  setMetadataInfo,
  wmsUrlInfo,
  setWmsUrlInfo,
  currentTitle,
  setCurrentTitle,
  isShowMetadataDialogOpen,
  setIsShowMetadataDialogOpen,
  getLayerName,
  isShowWmsUrlOpen,
  setIsShowWmsUrlOpen,
}) => {
  const classes = useStyles();

  const map = useMap();
  const [opacityValue, setOpacityValue] = useState(() => {
    if (activeLayer._container !== null) {
      return activeLayer._container.style.opacity * 100;
    } else {
      return 100;
    }
  });
  // const [currentTitle, setCurrentTitle] = useState('');
  const titleRef = useRef(null);

  const colortheme = createTheme({
    palette: {
      primary: { main: isDragging ? '#1B1212' : '#353935' },
    },
  });

  // const api = axios.create({
  //   baseURL:
  //     'https://idem.dhn.mar.mil.br/geoserver/ows?service=WMS&request=GetCapabilities',
  // });

  const api = axios.create({
    baseURL: process.env.REACT_APP_CAPABILITIES_URL,
  });

  console.log('REACT_APP', process.env.REACT_APP_CAPABILITIES_URL);

  const getAbstract = () => {
    let res = '';
    for (const layer in layers) {
      layers[layer].forEach((obj) => {
        if (obj.name === layerName) {
          res = obj.abstract;
        }
      });
    }
    return res;
  };

  const getBbox = () => {
    let res = [];
    for (const layer in layers) {
      layers[layer].forEach((obj) => {
        if (obj.name === layerName) {
          res = obj.bbox;
        }
      });
    }
    return res;
  };

  const handleClose = () => {
    const newLayers = [...activeLayers];
    activeLayer.options.openOperation = false;
    setActiveLayers(newLayers);
  };

  const handleSliderChange = (event, newValue) => {
    setOpacityValue(newValue);
    activeLayer.setOpacity(newValue / 100);
  };

  const centerLayerToMap = () => {
    if (map) {
      try {
        for (const layer in layers) {
          layers[layer].forEach((obj) => {
            if (obj.layer === activeLayer.options.layers) {
              const latLngs = obj.bbox.map((coord) => {
                return coord.map((point) => parseFloat(point));
              });
              map.fitBounds(latLngs);
            }
          });
        }
      } catch {}
    }
  };

  const handleShowMetadataDialog = () => {
    try {
      const newObj = {
        currentTitle: titleRef?.current?.textContent,
        currentAbstract: getAbstract(titleRef?.current?.textContent),
      };
      setMetadataInfo(newObj);
      setIsShowMetadataDialogOpen(true);
    } catch {}
  };

  const handleShowWmsUrlInfo = () => {
    try {
      {
        const {
          version,
          request,
          layers,
          styles,
          width,
          height,
          srs,
          format,
          transparent,
        } = activeLayer.wmsParams;

        const bbox = getBbox();
        const newBbox = bbox.map((item) => item.reverse());

        const newObj = {
          version: version,
          request: request,
          layers: layers,
          styles: '',
          bbox: `${bbox[0]},${bbox[1]}`,
          width: width,
          height: height,
          tiled: 'true',
          srs: srs,
          format: format,
          transparent: transparent,
        };

        setWmsUrlInfo(newObj);
        setIsShowWmsUrlOpen(true);
      }
    } catch {}
  };

  return (
    <>
      <Box
        style={{
          width: '100%',
          height: '100%',
          padding: '1px 8px',
          margin: '0px',
          maxHeight: '150px',
          fontSize: '0.9rem',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
        flex={1}
      >
        <Box
          {...draggableEventListener}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          className='draggable-item'
        >
          <Box itemRef={titleRef} sx={{ fontSize: '1em' }}>
            {layerName}
          </Box>
          <Tooltip title='Fechar'>
            <IconButton onClick={() => handleClose()} size='small'>
              <Close style={{ color: isDragging ? '#1B1212' : '#353935' }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          flex={1}
          sx={{
            pt: 1,
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',

            // flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <Tooltip title='Exibir Metadado'>
            <IconButton onClick={() => handleShowMetadataDialog()} size='small'>
              <Info style={{ color: isDragging ? '#1B1212' : '#353935' }} />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title='URL WMS'>
            <IconButton size='small' onClick={() => handleShowWmsUrlInfo()}>
              <Link style={{ color: isDragging ? '#1B1212' : '#353935' }} />
            </IconButton>
          </Tooltip> */}
          {/* <Tooltip title='Tabela de Atributos'>
            <IconButton size='small'>
              <TableChart
                style={{ color: isDragging ? '#1B1212' : '#353935' }}
              />
            </IconButton>
          </Tooltip> */}
          <Tooltip title='Zoom para a Camada'>
            <IconButton size='small' onClick={() => centerLayerToMap()}>
              <ZoomIn style={{ color: isDragging ? '#1B1212' : '#353935' }} />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title='Download Arquivo CSV'>
            <IconButton size='small'>
              <CloudDownload
                style={{ color: isDragging ? '#1B1212' : '#353935' }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title='Download Arquivo KMZ'>
            <IconButton size='small'>
              <CloudDownload
                style={{ color: isDragging ? '#1B1212' : '#353935' }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title='Download arquivo Shape'>
            <IconButton size='small'>
              <Archive style={{ color: isDragging ? '#1B1212' : '#353935' }} />
            </IconButton>
          </Tooltip> */}
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          sx={{ padding: '0 6px' }}
          //sx={{ pt: 1 }}
        >
          <Slider
            //defaultValue={opacityValue * 100}
            value={opacityValue}
            valueLabelDisplay='auto'
            scale={(val) => String(val + '%')}
            onChange={(ev, newVal) => handleSliderChange(ev, newVal)}
          />
          <ThemeProvider theme={colortheme}>
            <Typography
              className={classes.sliderLabel}
              color='primary'
              variant='caption'
            >
              Transparência
            </Typography>
          </ThemeProvider>
        </Box>
        {/* <ShowMetadataDialog
          title={currentTitle}
          layers={layers}
          activeLayer={activeLayer}
          isShowMetadataDialogOpen={isShowMetadataDialogOpen}
          setIsShowMetadataDialogOpen={setIsShowMetadataDialogOpen}
        /> */}
      </Box>
    </>
  );
};

export default LayerOperations;
