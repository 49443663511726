import React from 'react';
import { useMap } from 'react-leaflet';
import { useEffect } from 'react';
import L from 'leaflet';
import 'leaflet.polylinemeasure';

const options = {
  position: 'topleft',
  tooltipTextFinish: 'Clique para <b>encerrar</b><br>',
  tooltipTextDelete: 'Pressione SHIFT e clique para <b>deletar ponto</b>',
  tooltipTextMove: 'Clique e arraste para <b>mover o ponto</b><br>',
  tooltipTextResume: '<br>Pressione CTRL e clique para <b>retomar</b>',
  tooltipTextAdd: 'Pressione CTRL e clique para <b>adicionar ponto</b>',
  measureControlTitleOn: 'Iniciar medição', // Title for the control going to be switched on
  measureControlTitleOff: 'Encerrar medição',
  clearControlTitle: 'Limpar Medidas',
  showUnitControl: true,
  unitControlTitle: {
    text: 'Mudar Unidades',
    metres: 'metros',
    landmiles: 'milhas',
    nauticalmiles: 'milhas náuticas',
  },
  unitControlLabel: {
    // Unit symbols to show in the Unit Control button and measurement labels
    metres: 'm',
    kilometres: 'km',
    feet: 'ft',
    landmiles: 'mi',
    nauticalmiles: 'mn',
  },
};

const MeasureControl = () => {
  const map = useMap();

  useEffect(() => {
    let measureControl = L.control.polylineMeasure(options);
    measureControl.addTo(map);

    const el = document.getElementById(
      'polyline-measure-control'
    ).parentElement;

    if (el) {
      el.setAttribute('class', '');
      el.firstChild.setAttribute(
        'class',
        'MuiButtonBase-root MuiFab-root makeStyles-measureControl-12 MuiFab-primary'
      );
      el.firstChild.innerHTML =
        '<svg xmlns="http://www.w3.org/2000/svg" height="1.5em" fill="#ffffff" viewBox="0 0 512 512"><path d="M177.9 494.1c-18.7 18.7-49.1 18.7-67.9 0L17.9 401.9c-18.7-18.7-18.7-49.1 0-67.9l50.7-50.7 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 41.4-41.4 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 41.4-41.4 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 41.4-41.4 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 50.7-50.7c18.7-18.7 49.1-18.7 67.9 0l92.1 92.1c18.7 18.7 18.7 49.1 0 67.9L177.9 494.1z"/></svg>';
      el.lastChild.className = '';
      el.style.pointerEvents = 'auto';

      el.childNodes[1].setAttribute(
        'class',
        'btn-off MuiButtonBase-root MuiFab-root makeStyles-unitControl-13 MuiFab-primary'
      );
      el.children[1].style.color = '#fff';
      el.children[1].style.fontSize = '.6rem';
    }

    map.on('polylinemeasure:toggle', function (e) {
      el.lastChild.classList.toggle('btn-off');
      if (!el.lastChild.classList.contains('btn-off')) {
        el.firstChild.style.backgroundColor = '#041581';
      }
    });

    return () => {
      map.removeLayer(measureControl);
    };
  }, []);

  return null;
};

export default MeasureControl;
