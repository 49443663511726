import React, { useEffect } from 'react';
import { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  ArrowDownward,
  ArrowUpward,
  DeleteOutline,
  MoreHoriz,
} from '@material-ui/icons';
import {
  Box,
  Checkbox,
  List,
  IconButton,
  Tooltip,
  makeStyles,
  ListItem,
} from '@material-ui/core';
import LayerOperations from './LayerOperations';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  listItem: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    color: 'black',
    height: '150px',
    paddingTop: '0px',
    paddingBottom: '0px',
    marginTop: '16px',
    marginBottom: '16px',
    paddingLeft: { sm: '16px' },
    '&:hover': {
      paddingTop: 0,
      borderRadius: '2px',
    },
  },
  listItemBox: {
    '&:hover': {
      borderRight: '1px solid #708090!important',
      borderTop: '1px solid #708090!important',
      borderBottom: '1px solid #708090!important',
      borderLeft: '8px solid #303f9f!important',
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)!important',
      transition: '.3s',
    },
    height: '100%',
  },
}));

const getItemStyle2 = (isDragging, draggableStyle) => ({
  minHeight: '120px',
  width: '100%',
  borderRadius: '5px',
  margin: '0px 0',
  fontWeight: '600',
  padding: '0',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  //   color: isDragging ? '#1B1212' : '#353935',
  // border: isDragging ? '3px solid #041581' : '2px solid #3f51b5',
  // background: '#f6f6f6',
  // backgroundColor: 'fff', //'#f6f6f6', //'#EDEDED',
  //border: '2px solid #3f51b5',
  // border: '1px solid #353935',
  //borderLeft: isDragging ? '4px solid #3f51b5' : 'none',
  borderRight: '1px solid lightgrey',
  borderTop: '1px solid lightgrey',
  borderBottom: '1px solid lightgrey',
  borderLeft: '8px solid #3f51b5',
  boxShadow:
    'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',

  // ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  width: '100%',
  height: '100%',
  paddingTop: '4px',
});

const LayerManagerDrawer = ({
  layers,
  setLayers,
  activeLayers,
  setActiveLayers,
  metadataInfo,
  setMetadataInfo,
  wmsUrlInfo,
  setWmsUrlInfo,
  currentTitle,
  setCurrentTitle,
  isShowMetadataDialogOpen,
  setIsShowMetadataDialogOpen,
  isShowWmsUrlOpen,
  setIsShowWmsUrlOpen,
}) => {
  const [checked, setChecked] = useState(true);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const getWidthSize = () => {
    const { innerWidth } = window;
    return { innerWidth };
  };
  // const [widthSize, setWidthSize] = useState(() => getWidthSize());
  const classes = useStyles();

  const handleCheckboxChange = (event, styles) => {
    const res = styles === 'layer-off' ? false : true;
    setChecked(res);
  };

  const handleLayerOperationOpen = (layer) => {
    const newLayers = [...activeLayers];
    layer.options.openOperation = true;
    setActiveLayers(newLayers);
  };

  const getLayerName = (name) => {
    let res = '';
    for (const layer in layers) {
      layers[layer].forEach((obj) => {
        if (obj.layer === name) {
          res = obj.name;
        }
      });
    }
    return res;
  };

  //deletar layer
  const uncheckLayer = (name, index) => {
    for (const layer in layers) {
      layers[layer].forEach((obj) => {
        if (obj.layer === name) {
          obj.checked = false;
        }
      });
    }
    const newLayers = { ...layers };
    setLayers(newLayers);
  };

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemsReordered = reorder(
      activeLayers,
      result.source.index,
      result.destination.index
    );

    setActiveLayers(itemsReordered);
  }

  const swapElements = (arr, i1, i2) => {
    const newArray = [...arr];
    [newArray[i1], newArray[i2]] = [newArray[i2], newArray[i1]];
    setActiveLayers(newArray);
  };

  const ArrowButtons = ({ index }) => {
    return (
      <Box
        style={{ marginRight: '10px' }}
        display='flex'
        flexDirection='column'
        alignContent='center'
        sx={{ display: { xs: 'flex' } }}
        //   {...provided.draggableProps}
      >
        <IconButton
          title='Mover Camada para cima'
          size='small'
          disabled={index === 0 ? true : false}
          onClick={() => swapElements(activeLayers, index, index - 1)}
          style={{
            color: index === 0 ? 'lightgrey' : 'black',
            padding: '0',
            margin: '0',
          }}
        >
          <ArrowUpward />
        </IconButton>
        <IconButton
          title='Mover Camada para baixo'
          size='small'
          disabled={index === activeLayers.length - 1 ? true : false}
          onClick={() => {
            swapElements(activeLayers, index, index + 1);
            setIsTooltipOpen(false);
          }}
          style={{
            padding: '0',
            margin: '0',
            color: index === activeLayers.length - 1 ? 'lightgrey' : 'black',
          }}
        >
          <ArrowDownward />
        </IconButton>
      </Box>
    );
  };

  const OperationButtons = ({ activeLayer, index }) => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Tooltip
          title={
            activeLayer.wmsParams.styles === 'layer-off'
              ? 'Exibir Camada'
              : 'Não Exibir Camada'
          }
        >
          <Checkbox
            style={{
              color: 'black',
            }}
            defaultChecked={
              activeLayer.wmsParams.styles === 'layer-off' ? false : true
            }
            onChange={(e) => {
              e.target.checked
                ? activeLayer.setParams({ styles: '' })
                : activeLayer.setParams({
                    styles: 'layer-off',
                  });
              handleCheckboxChange(e, activeLayer.wmsParams.styles);
            }}
          />
        </Tooltip>
        <Tooltip title='Remover Camada'>
          <IconButton
            size='small'
            onClick={() => {
              const newActiveLayers = [...activeLayers];
              newActiveLayers.splice(index, 1);
              uncheckLayer(activeLayer.options.layers, index);
              setActiveLayers(newActiveLayers);
            }}
          >
            <DeleteOutline
              style={{
                color: 'black',
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title='Mais Operações Sobre a Camada'>
          <IconButton
            size='small'
            onClick={() => {
              handleLayerOperationOpen(activeLayer);
            }}
          >
            <MoreHoriz
              style={{
                color: 'black',
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  useEffect(() => {
    const arrayLength = activeLayers.length;
    activeLayers.forEach((activeLayer, index) => {
      activeLayer.setZIndex(arrayLength - index);
    });
  }, [activeLayers]);

  useEffect(() => {
    activeLayers.forEach(
      (activeLayer) => (activeLayer.options.openOperation = false)
    );
    const newLayers = [...activeLayers];
    setActiveLayers(newLayers);
  }, []);

  // useEffect(() => {
  //   function handleWindowResize() {
  //     setWidthSize(getWidthSize());
  //   }
  //   console.log('AAAAAA', widthSize);

  //   window.addEventListener('resize', handleWindowResize);

  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // }, [widthSize]);

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <List
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {activeLayers.map((activeLayer, index) => (
                <Draggable
                  key={`${activeLayer._leaflet_id}`}
                  draggableId={`${activeLayer._leaflet_id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <>
                      <ListItem
                        className={classes.listItem}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <Box
                          className={classes.listItemBox}
                          display='flex'
                          //       {...provided.dragHandleProps}
                          style={getItemStyle2(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {!activeLayer.options?.openOperation ? (
                            <Box
                              {...provided.dragHandleProps}
                              display='flex'
                              justifyContent='space-between'
                              sx={{
                                padding: grid * 2,
                                width: '100%',
                              }}
                              alignItems='center'
                            >
                              <Box
                                display='flex'
                                justifyContent='space-evenly'
                                alignItems='center'
                                style={{ width: '100%' }}
                              >
                                <ArrowButtons index={index} />
                                <Box
                                  style={{
                                    color: 'black',
                                    width: '200px',
                                  }}
                                >
                                  {getLayerName(activeLayer.options.layers)}
                                </Box>
                                <OperationButtons
                                  activeLayer={activeLayer}
                                  index={index}
                                />
                              </Box>
                            </Box>
                          ) : (
                            <LayerOperations
                              isDragging={snapshot.isDragging}
                              layerName={getLayerName(
                                activeLayer.options.layers
                              )}
                              activeLayer={activeLayer}
                              layers={layers}
                              draggableEventListener={{
                                ...provided.dragHandleProps,
                              }}
                              activeLayers={activeLayers}
                              setActiveLayers={setActiveLayers}
                              metadataInfo={metadataInfo}
                              setMetadataInfo={setMetadataInfo}
                              wmsUrlInfo={wmsUrlInfo}
                              setWmsUrlInfo={setWmsUrlInfo}
                              currentTitle={currentTitle}
                              setCurrentTitle={setCurrentTitle}
                              isShowMetadataDialogOpen={
                                isShowMetadataDialogOpen
                              }
                              setIsShowMetadataDialogOpen={
                                setIsShowMetadataDialogOpen
                              }
                              getLayerName={getLayerName}
                              isShowWmsUrlOpen={isShowWmsUrlOpen}
                              setIsShowWmsUrlOpen={setIsShowWmsUrlOpen}
                            />
                          )}
                        </Box>
                      </ListItem>
                    </>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default LayerManagerDrawer;
