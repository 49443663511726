import React, { Component, useEffect, useState } from 'react';
import { useMap, WMSTileLayer } from 'react-leaflet';
import * as WMS from 'leaflet.wms';
import L from 'leaflet';
import json from "./avradio.json";
import { render } from '@testing-library/react';
import redIconImage from '../../assets/images/marker-icon-red.png';
const regex = /-*\d*\.*\d*,-*\d*\.*\d*/;

const avradio = () => {
    
    let avisos = {
        type: 'FeatureCollection',
        features: [],
    };

    json.avisos.map((aviso) => {
        let feature = {
            type: 'Feature',
        };
        if (aviso.geometry) {
            let geometry = { type: 'Point' };
            if (aviso.geometry.startsWith('new google.maps.Marker')) {
                const latlong = aviso.geometry
                    .split('LatLng')[1]
                    .split('|')[0]
                    .match(regex)[0]
                    .split(',');
                geometry['coordinates'] = [
                    parseFloat(latlong[1]),
                    parseFloat(latlong[0]),
                ];
            }
            feature['geometry'] = geometry;
            feature['properties'] = {
                numero: aviso.numero,
                costa: aviso.costa,
                textoPT: aviso.textoPT,
                textoEN: aviso.textoEN,
                tipo: aviso.tipo,
            };
        }
        avisos.features.push(feature);
    });

    return avisos;
};

const styleMetareaV = {
    color: '#000000',
    weight: 2,
    opacity: 0.6,
};

var redIcon = new L.Icon({
    iconUrl: redIconImage,
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});


const layer = L.geoJSON(avradio(), {
    pointToLayer: function (feature, latlng) {
        return L.marker(latlng, { icon: redIcon });
    },
    onEachFeature: function (aviso, layer) {
        layer.bindPopup(
            '<h3>' +
            aviso.properties.numero +
            '</h3><p>' +
            aviso.properties.textoPT +
            '</p>'
        );
    },
})

const AvisoRadioLayer = (props) => {
    const { url, options, layers } = props;

    const map = useMap();
    const [checked, setChecked] = useState(props.checked)
    const [avisos, setAvisos] = useState()


    useEffect(() => {
        setAvisos(layer)
    }, [props.checked])

    if (props.checked) {
        if (avisos) {
            avisos.addTo(map);
        }
    } else {
        if (avisos) {
            avisos.remove();
            setAvisos(null);
        }

    }

    return null

    // return (
    //     <WMSTileLayer
    //         url="https://10.5.193.3/geoserver/ows?service=WMS&"
    //         layers={"DHN:av_radio"}
    //         format="image/png"
    //         transparent={true}
    //         zIndex={1}
    //         opacity={1}
    //     />
    // )

    // // Add WMS source/layers
    // const source = WMS.source(url, options);

    // for (let name of layers) {
    //   source.getLayer(name).addTo(map);
    // }

    // map.on('click', function (e) {
    //   // Build the URL for a GetFeatureInfo
    //   console.log(source.getLayer(layers[0]));
    // });
}


export default AvisoRadioLayer;
