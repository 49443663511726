import * as React from 'react';

// Styling functions
import { styled } from '@material-ui/core/styles';

// Material UI components
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  createStyles({
    // const useStyles = createStyles({
    title: {
      display: 'flex',
      justifyContent: 'center',
    },
    progress: {
      display: 'flex',
      marginTop: '10px',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: '0',
      right: '0',
      textAlign: 'center',
      zIndex: 1000,
    },
  })
);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  // '& .MuiDialogTitle-root': {},
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  const classes = useStyles();

  return (
    <DialogTitle className={classes.title} {...other}>
      {children}
    </DialogTitle>
  );
}

export function PopUp(props) {
  const [open, setOpen] = React.useState(props.openStatus);
  const { setOpenStatus } = props;

  const classesCss = useStyles();

  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <BootstrapDialog
      open={open}
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
    >
      <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
        {props.title}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography align='justify' gutterBottom>
          {props.content}
        </Typography>
        <Divider />
        <div>
          {props.loadingData ? (
            <Typography>
              <CircularProgress
                thickness={10}
                size={40}
                className={classesCss.progress}
              />
            </Typography>
          ) : null}
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
}
