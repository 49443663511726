import { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Add } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  button: {
    color: 'white',
    marginTop: '10px',
    background: '#3f51b5',
    '&:hover': {
      color: 'white',
      background: '#303f9f',
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    },
  },
}));

const CaptionDrawer = (props) => {
  const classes = useStyles();

  const handleDrawerClose = () => {
    props.setShowCaptionDrawer(false);
  };

  const getLayerName = (name) => {
    let res = '';
    for (const layer in props.layers) {
      props.layers[layer].forEach((obj) => {
        if (obj.layer === name) {
          res = obj.name;
        }
      });
    }
    return res;
  };

  return (
    <>
      <Drawer
        open={props.open}
        variant={props.componentLegend.length > 0 ? 'persistent' : 'temporary'}
        anchor='right'
        onClose={handleDrawerClose}
      >
        <IconButton
          onClick={handleDrawerClose}
          style={{ borderRadius: 0, height: '64px' }}
        >
          <CloseIcon />
        </IconButton>
        {/* <Box display='flex' justifyContent='center'>
          <Typography variant='h6'>Legendas</Typography>
        </Box> */}
        <Divider />
        <Box
          style={{
            margin: '0',
            width: '350px',
            overflowX: 'hidden',
            scrollbarWidth: 'none',
          }}
        >
          {props.componentLegend.length === 0 ? (
            <Box
              style={{
                height: '80vh',
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <Box
                style={{
                  height: '80px',
                  width: '100%',
                }}
                display='flex'
                flexDirection='column'
                justifyContent='space-evenly'
                alignItems='center'
                textAlign='center'
              >
                <Typography variant='h5'>
                  {props.activeLayers.length === 0
                    ? 'Não há camadas ativas.'
                    : 'Não há legendas para a(s) camada(s) selecionada(s)'}{' '}
                </Typography>
                <Button
                  className={classes.button}
                  variant='contained'
                  startIcon={<Add />}
                  onClick={props.handleDrawerOpen}
                >
                  ADICIONAR
                </Button>
              </Box>
            </Box>
          ) : (
            <List style={{ margin: 0, width: '100%', padding: 0 }}>
              {props.componentLegend.map((img, index) => {
                return (
                  <>
                    <ListItem
                      key={index}
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Box style={{ paddingBottom: '8px' }}>
                        <Typography variant='subtitle1'>
                          {getLayerName(Object.keys(img)[0])}
                        </Typography>
                      </Box>
                      <img
                        src={JSON.stringify(img[Object.keys(img)]).replace(
                          /["']/g,
                          ''
                        )}
                      />
                    </ListItem>
                    <Divider />
                  </>
                );
              })}
            </List>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default CaptionDrawer;
