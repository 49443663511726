import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  divider: {
    background: '#3f51b5',
  },
  button: {
    color: '#3f51b5',
    border: '1px solid #3f51b5',
    background: 'white',
    '&:hover': {
      color: '#041581',
      border: '1px solid #3f51b5',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    },
  },
}));

const ShowWmsUrl = ({ wmsUrlInfo, isShowWmsUrlOpen, setIsShowWmsUrlOpen }) => {
  const classes = useStyles();
  const [url, setUrl] = useState('');

  const handleClose = () => {
    setIsShowWmsUrlOpen(false);
  };

  const createUrl = () => {
    const {
      version,
      request,
      layers,
      styles,
      bbox,
      width,
      height,
      tiled,
      srs,
      format,
      transparent,
    } = wmsUrlInfo;
    const newUrl = `https://${process.env.REACT_APP_CAPABILITIES_URL}/geoserver/ows?service=WMS&version=${version}&request=${request}&layers=${layers}&styles=${styles}&bbox=${bbox}&width=${width}&height=${height}&tiled=${tiled}&srs=${srs}&format=${format}&transparent=${transparent}`;
    setUrl(newUrl);
  };

  useEffect(() => {
    createUrl();
  });

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 320,
        bgcolor: 'background.paper',
      }}
    >
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 435 } }}
        maxWidth='sm'
        open={isShowWmsUrlOpen}
      >
        <DialogTitle>
          <Box>
            <Typography variant='h5'>URL WMS</Typography>
          </Box>
        </DialogTitle>
        <Divider variant='middle' classes={{ root: classes.divider }} />
        <DialogContent>
          <Box display='flex' flexDirection='column' sx={{ gap: '20px' }}>
            {url}
          </Box>
        </DialogContent>
        <Divider variant='middle' classes={{ root: classes.divider }} />
        <DialogActions>
          <Button
            size='small'
            onClick={() => handleClose()}
            variant='outlined'
            classes={{ root: classes.button }}
            // style={{
            //   color: 'white',
            //   backgroundColor: '#3f51b5',
            //   '&:hover': { backgroundColor: '#041581' },
            // }}
          >
            FECHAR
          </Button>
          <Button
            size='small'
            onClick={() => handleClose()}
            variant='outlined'
            classes={{ root: classes.button }}
            // style={{
            //   color: 'white',
            //   backgroundColor: '#3f51b5',
            //   '&:hover': { backgroundColor: '#041581' },
            // }}
          >
            COPIAR
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ShowWmsUrl;
