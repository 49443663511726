import React from 'react';
import {
  makeStyles,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Map } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    maxWidth: '100vw',
    //height: '8vh',
  },
  menuButton: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
      fontWeight: 600,
    },
    color: '#FFF',
  },
  titleLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
  btn: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.55rem',
    },
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const preventDefault = (event) => event.preventDefault();

  return (
    <AppBar className={classes.root} position='static'>
      <Toolbar>
        {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    <MenuIcon />
                </IconButton> */}

        <Typography variant='h6' className={classes.title}>
          <Link className={classes.titleLink} to='/'>
            GeoPortal-DHN
          </Link>
        </Typography>

        <Button
          variant='outlined'
          color='inherit'
          startIcon={<Map />}
          className={classes.btn}
          component={Link}
          to='/map'
        >
          Visualizador de Mapas
        </Button>
      </Toolbar>
    </AppBar>
  );
};
export default NavBar;
