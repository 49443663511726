import React, { Component, useEffect, useLayoutEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { useMap, WMSTileLayer } from 'react-leaflet';
import * as WMS from 'leaflet.wms';
import L from 'leaflet';
import json from './avradio.json';
import { render } from '@testing-library/react';
import farolIconImage from '../../assets/images/farol.png';
import { FeatureGroup, LayersControl } from 'react-leaflet';

// Import MaterialUI components */

import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
  withStyles,
  Tooltip,
} from '@material-ui/core';

// Import MaterialUI icons

import CloseIcon from '@material-ui/icons/Close';
import { Height } from '@material-ui/icons';

const regex = /-*\d*\.*\d*,-*\d*\.*\d*/;

const farois = () => {
  let farols = {
    type: 'FeatureCollection',
    features: [],
    // style: null,
  };

  json.farois.map((farol) => {
    let feature = {
      type: 'Feature',
    };
    if (farol.geometry) {
      let geometry = { type: 'Point' };
      if (farol.geometry.startsWith('new google.maps.Marker')) {
        const latlong = farol.geometry
          .split('LatLng')[1]
          .split('|')[0]
          .match(regex)[0]
          .split(',');
        geometry['coordinates'] = [
          parseFloat(latlong[1]),
          parseFloat(latlong[0]),
        ];
      }

      feature['geometry'] = geometry;
      feature['properties'] = {
        nome: farol.nome,
        local: farol.local,
        alcance: farol.alcance,
        dataInauguracao: farol.dataInauguracao,
        descricao: farol.descricao,
        fotos: farol.fotos,
      };
      feature['options'] = {
        [feature['wmsParams']]: {
          checked: false,
          name: 'Farol',
          wmsParams: [],
        },
      };
    }

    farols.features.push(feature);
  });

  return farols;
};

var farolIcon = new L.Icon({
  iconUrl: farolIconImage,
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [18, 35],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [35, 41],
});

const useStyles = makeStyles((theme) => ({
  popUpContainer: {
    position: 'relative',
    top: 0,
    left: 0,
    // margin: '0 auto',
    // marginTop: '0',
    // marginBottom: '0',
    marginRight: '-5px',
    // margin: '0 auto',
    padding: 0,
    zIndex: 99999,
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    width: '300px',
    height: '450px',
    overflowX: 'hidden',    
    overflow: "auto",
    scrollbarWidth: "none", // Hide the scrollbar for firefox
    '&::-webkit-scrollbar': {
        display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
    },
    '&-ms-overflow-style:': {
        display: 'none', // Hide the scrollbar for IE
    },
  },  
  popUpSlider: {
    justifyContent: 'center',
    // scrollSnapType: 'x mandatory',
  },

  popUpSlide: {
    scrollSnapAlign: 'start',
    flexShrink: 0,
    width: '300px',
    height: '300px',
    marginRight: '50px',
    borderRadius: '10px',
    transformOrigin: 'center center',
    transform: `scale(1)`,
    transition: 'transform 0.5s',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '100px',
  },

  img: {
    objectFit: 'fill',
    position: 'absolute',
    borderRadius: '50%',
    width: '280px',
    height: '280px',
    border: '5px solid green',
  },

  nome: {
    margin: '0.5rem 0',
    padding: '0',
    border: '1px solid green',
    fontFamily: 'arial',
    textAlign: 'center',
    fontSize: '1.125rem',
  },

  descricao: {
    fontFamily: 'arial, monospace',
    fontSize: '0.9rem',
    textAlign: 'justify',
  },
  /* topo | direita | inferior | esquerda */
  leaftPopupStyle: {
    // padding: 0,
    // display:'flex',
    // margin: '-10px 5px 0px 0px',
    border: '3px solid green',
    borderRadius: '15px',
  },
}));

const FarolLayer = (props) => {
  const { url, options, checked, activeLayers, layers } = props;

  const setActiveLayers = () => props.setActiveLayers;

  // console.log(`To no construtor`);

  const map = useMap();
  const classes = useStyles();
  const [farols, setFarols] = useState();

  const layer = L.geoJSON(farois(), {
    style: function (feature) {
      return {
        className: classes.leaftPopupStyle,
        // zIndex: 1,
      };
    },
    pointToLayer: function (feature, latlng) {
      return L.marker(latlng, { icon: farolIcon });
    },
    // onEachFeature: function (farol, layer) {
    //     try {

    //         // Renderiza o componente Drawer dentro do elemento DOM criadoo

    //         // const container = document.createElement('div'); // Crie um elemento DOM para conter o Drawer
    //         //ONCLICK FUNCIONANDO <===================
    //         // layer.on('click', function (e) {
    //         //     const container = document.getElementById('paEbola'); // Crie um elemento DOM para conter o Drawer

    //         //     setImgPath(farol.properties.fotos)

    //         //     console.log(`fora chkeced ${props.checked}`);
    //         //     console.log(`fora farolState ${farolState}`);

    //         //     if (props.checked) {
    //         //         setFarolState(true)
    //         //         if (farolState) {
    //         //             <LayersControl.Overlay>
    //         //                 <FeatureGroup>
    //         //                     {
    //         //                         ReactDOMServer.renderToStaticMarkup(
    //         //                         // ReactDOM.render(
    //         //                             <Drawer open={farolState} onClose={handleStateClose} onOpen={handleStateOpen} style={{
    //         //                                 maxWidth: '100px',
    //         //                                 width: '100px'
    //         //                             }}>
    //         //                                 {/* <IconButton onClick={() => {console.log(`state ->${state}<-`)}}> */}
    //         //                                 <IconButton onClick={handleStateClose}>
    //         //                                     <CloseIcon id="closeButton" />
    //         //                                 </IconButton>
    //         //                                 <Divider />
    //         //                                 <List >
    //         //                                     <ListItem >
    //         //                                         <div >
    //         //                                             <div>
    //         //                                                 {/* Titulo */}
    //         //                                                 {farol.properties.nome}
    //         //                                             </div>
    //         //                                             <div>
    //         //                                                 {/* Desc */}
    //         //                                                 {farol.properties.descricao}

    //         //                                                 <img src={imgPath} />
    //         //                                                 {
    //         //                                                     console.log(`THE imgPath --->${imgPath.length}<----`)
    //         //                                                 }
    //         //                                             </div>
    //         //                                         </div>
    //         //                                     </ListItem>

    //         //                                 </List>
    //         //                             </Drawer>
    //         //                             // ,
    //         //                             // container
    //         //                         )
    //         //                     }
    //         //                 </FeatureGroup>
    //         //             </LayersControl.Overlay>
    //         //         }
    //         //     }
    //         // })
    //         console.log(`to aqui no each`);

    //         layer.bindPopup(`
    //         <div class=${classes.popUpContainer}>
    //             <div class=${classes.popUpSlider}>
    //                     <div class=${classes.popUpSlide}>
    //                          <img class=${classes.img} src="${farol.properties.fotos[0]}" />
    //                     </div>
    //                     </div>
    //                     <div class=${classes.nome}>
    //                         <p>${farol.properties.nome}</p>
    //                     </div>
    //                     <div class=${classes.descricao}>
    //                         ${farol.properties.descricao}
    //                     </div>
    //             </div>
    //         </div>
    //         `, {
    //             closeButton: false
    //         })

    //         layer.on('click', () => {
    //             setSelectedFarol(farol);
    //             handleStateOpen();
    //         });

    //     } catch (error) {
    //         console.log(`Bind error => ${error} `);
    //     }
    //     /**
    //      * Drawer @ Stub
    //      */

    //     // layer.bindPopup(
    //     //     '<h3>' +
    //     //     farol.properties.nome +
    //     //     '</h3> <p>' +
    //     //     farol.properties.descricao +
    //     //     '</p>'
    //     // );
    // },

    onEachFeature: function (farol, layer) {
      const loga = function (param1, param2) {
        console.log(`${param1} : ${JSON.stringify(param2)}`);
      };

      var popupContent = //   .setLatLng(map.getBounds().getCenter())
        L.popup({
          className: classes.leaftPopupStyle,
          autoPan: true,
          //   minWidth: 250,
          //   maxWidth: 300,
          closeButton: false,
        }).setContent(`
                <div class=${classes.popUpContainer}>
                    <div class=${classes.popUpSlider}>
                            <div class=${classes.popUpSlide}>
                                 <img class=${classes.img} src="${farol.properties.fotos[0]}" />
                            </div>
                            </div>
                            <div class=${classes.nome}>
                                <p>${farol.properties.nome}</p>
                            </div>
                            <div class=${classes.descricao}>
                                ${farol.properties.descricao}
                            </div>
                            
                    </div>
                </div>
                `);

      //<div>
      //  <input type="button" value="Log aqui" onclick="${(layer) => loga(layer, activeLayers)}">
      //</div>

      /**
       * sianis nauticos / farois ativos
       *
       */
      layer.bindPopup(popupContent);
    },
    wmsParam: {
      styles: {},
    },
  });

  // const handleDrawerOpen = () => {
  //     setState(true);
  // };
  // const handleDrawerClose = () => {
  //     setState(false);
  // };

  useEffect(() => {
    setFarols(layer);
    // let mapLayers = [];
    // let newArray = [];

    // map.eachLayer(function (layer) {
    //     console.log(`layer Name no farol layer ==> ${layer}`);
    // })

    // function getDifference(array1, array2) {
    //     return array1.filter((element) => {
    //         return !array2.includes(element);
    //     });
    // }

    // if (activeLayers.length === 0) {
    //     setActiveLayers(mapLayers);
    // } else {
    //     newArray = getDifference(mapLayers, activeLayers);
    //     if (newArray.length > 0) {
    //         setActiveLayers((prev) => [newArray[0], ...prev]);
    //     }
    // }
  }, [checked]);

  // useLayoutEffect(() => {
  //     // setFarols(layer)
  //     // console.log(`layout effect`);
  //     setFarols(layer)
  //     // }, [props.checked, state])
  // }, [props.checked, farolState])
  // }, [])

  if (checked) {
    if (farols) {
      console.log('farols', farols);
      farols.addTo(map);
    }
  } else {
    if (farols) {
      farols.remove();
      setFarols(null);
    }
  }

  // return (
  //     <div>
  //         {selectedFarol && (
  //             <Drawer
  //                 open={farolState}
  //                 onClose={handleStateClose}
  //                 onOpen={handleStateOpen}
  //             >
  //                 <div>
  //                     <h3>{selectedFarol.properties.nome}</h3>
  //                     <p>{selectedFarol.properties.descricao}</p>
  //                     {selectedFarol.properties.fotos.map((value, idx) => (
  //                         <img key={idx} src={value} alt={`Image ${idx}`} />
  //                     ))}
  //                 </div>
  //             </Drawer>
  //         )}
  //     </div>
  // );

  // return null

  // return (() => setActiveLayers(
  //     <LayersControl position='topleft' hideSingleBase={true}>
  //         <LayersControl.Overlay checked={true}>
  //             <FeatureGroup>
  //                 <WMSTileLayer
  //                     url="https://idem.dhn.mar.mil.br/geoserver/ows?SERVICE=WMS&"
  //                     noRedraw={false}
  //                     // layers={"carta_sinotica:" + layers[sliderValue]}
  //                     layers={farols}
  //                     // format="image/png"
  //                     transparent={true}
  //                     zIndex={999}
  //                     opacity={1}
  //                 />
  //             </FeatureGroup>
  //         </LayersControl.Overlay>
  //     </LayersControl>
  // ))

  return null;
};

export default FarolLayer;
