import { Component, useEffect, useRef, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Add, CheckBox } from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  controlContainer: {
    position: 'fixed',
    top: theme.spacing(54),
    left: theme.spacing(4),
    zIndex: 2,
    backgroundColor: 'white',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
  controlContainerSinoticaOpened: {
    position: 'fixed',
    top: theme.spacing(64),
    left: theme.spacing(4),
    zIndex: 2,
    backgroundColor: 'white',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    transition: '0.3s',
  },
  menuLine: {
    padding: '0px 8px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'lightgray',
      fontWeight: 'bolder',
      transition: '.3s',
      fontSize: '2rem',
    },
    '&:hover .MuiTypography-root': {
      fontWeight: 'bold',
    },
  },
  checkbox: {
    color: '#303f9f',
    '&.Mui-checked': {
      color: '#303f9f',
    },
  },
}));

const VENTO_FILTER =
  "'VENTO FORTE', 'VENTO MUITO FORTE', 'VENTO FORTE/MUITO FORTE', 'VENTO DURO', 'VENTO MUITO DURO', 'VENTO FORTE/DURO', 'VENTO FORTE/MUITO DURO', 'VENTO MUITO FORTE/DURO', 'VENTO DURO/MUITO DURO', 'VENTO MUITO FORTE/MUITO DURO', 'VENTO TEMPESTUOSO', 'VENTO DURO/TEMPESTUOSO', 'VENTO MUITO DURO/TEMPESTUOSO', 'FURACAO',";
const MAR_FILTER =
  "'MAR GROSSO', 'MAR MUITO GROSSO', 'MAR GROSSO/MUITO GROSSO', 'MAR ALTO', 'MAR MUITO GROSSO/ALTO', 'MAR GROSSO ALTO', 'MAR MUITO ALTO', 'MAR MUITO GROSSO/MUITO ALTO', 'FENOMENAL',";
const RESSACA_FILTER = "'RESSACA',";
const BAIXA_VISIBILIDADE_FILTER = "'BAIXA VISIBILIDADE',";
const ESPECIAL_FILTER = "'ESPECIAL',";

const AvisosMauTempoControl = ({
  setShowAvisoMauTempoControl,
  activeLayers,
  iscartaSinoticaButtonOpened,
}) => {
  const getAvisoMauTempoLayer = () => {
    return activeLayers.filter((activeLayer) => {
      if (activeLayer.wmsParams) {
        if (activeLayer.wmsParams.layers === 'smm:avisos_teste') {
          return activeLayer;
        }
      }
    });
  };

  const getCqlExpression = () => {
    const avisoMauTempoLayer = getAvisoMauTempoLayer();
    if (avisoMauTempoLayer.length === 1) {
      let cqlExpression = avisoMauTempoLayer[0].wmsParams.cql_filter;
      return cqlExpression;
    }
    return '';
  };
  const [ventoChecked, setVentoChecked] = useState(
    getCqlExpression().includes(VENTO_FILTER)
  );
  const [marChecked, setMarChecked] = useState(
    getCqlExpression().includes(MAR_FILTER)
  );
  const [ressacaChecked, setRessacaChecked] = useState(
    getCqlExpression().includes(RESSACA_FILTER)
  );
  const [baixaVisibilidadeChecked, setbaixaVisibilidadeChecked] = useState(
    getCqlExpression().includes(BAIXA_VISIBILIDADE_FILTER)
  );
  const [especialChecked, setEspecialChecked] = useState(
    getCqlExpression().includes(ESPECIAL_FILTER)
  );

  const classes = useStyles();

  const toggleAvisos = (isChecked, filterExpression) => {
    const avisoMauTempoLayer = getAvisoMauTempoLayer();

    switch (filterExpression) {
      case 'VENTO':
        filterExpression = VENTO_FILTER;
        break;
      case 'MAR':
        filterExpression = MAR_FILTER;
        break;
      case 'RESSACA':
        filterExpression = RESSACA_FILTER;
        break;
      case 'BAIXA VISIBILIDADE':
        filterExpression = BAIXA_VISIBILIDADE_FILTER;
        break;
      case 'ESPECIAL':
        filterExpression = ESPECIAL_FILTER;
        break;
      default:
        filterExpression = '';
    }

    if (!isChecked) {
      if (avisoMauTempoLayer.length === 1) {
        avisoMauTempoLayer[0].setParams({
          cql_filter: avisoMauTempoLayer[0].wmsParams.cql_filter.replace(
            filterExpression,
            ''
          ),
        });
      }
    } else {
      if (avisoMauTempoLayer.length === 1) {
        let expression = avisoMauTempoLayer[0].wmsParams.cql_filter;

        if (!expression.includes(filterExpression)) {
          avisoMauTempoLayer[0].setParams({
            cql_filter: [
              expression.slice(0, 15),
              filterExpression,
              expression.slice(15),
            ].join(''),
          });
        }
      }
    }
  };

  return (
    <Box
      //  onMouseLeave={() => setShowAvisoMauTempoControl(true)}
      className={
        iscartaSinoticaButtonOpened
          ? classes.controlContainerSinoticaOpened
          : classes.controlContainer
      }
      sx={{
        border: '2px solid #303f9f',
        display: 'inline-flex',
        borderRadius: '8px',
      }}
    >
      <Box display='flex' flexDirection='column'>
        <Box
          sx={{
            p: '6px',
            textAlign: 'center',
            backgroundColor: '#303f9f',
            color: 'white',
          }}
        >
          <Typography>Avisos de Mau Tempo</Typography>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          className={classes.menuLine}
          onClick={(e) => {
            setVentoChecked((prev) => !prev);
            toggleAvisos(!ventoChecked, 'VENTO');
          }}
        >
          <Checkbox
            size='small'
            className={classes.checkbox}
            style={{
              padding: '4px 2px',
              '&:hover': { color: 'white' },
            }}
            checked={ventoChecked}
            onChange={(e) => {
              setVentoChecked(e.target.checked);
              toggleAvisos(e.target.checked, 'VENTO');
            }}
          />
          <Typography>Vento</Typography>
        </Box>

        <Box
          display='flex'
          alignItems='center'
          className={classes.menuLine}
          onClick={(e) => {
            setMarChecked((prev) => !prev);
            toggleAvisos(!marChecked, 'MAR');
          }}
        >
          <Checkbox
            size='small'
            className={classes.checkbox}
            style={{ padding: '4px 2px' }}
            checked={marChecked}
            onChange={(e) => {
              setMarChecked(e.target.checked);
              toggleAvisos(e.target.checked, 'MAR');
            }}
          />
          <Typography>Mar</Typography>
        </Box>
        <Box
          display='flex'
          alignItems='center'
          className={classes.menuLine}
          onClick={(e) => {
            setRessacaChecked((prev) => !prev);
            toggleAvisos(!ressacaChecked, 'RESSACA');
          }}
        >
          <Checkbox
            size='small'
            className={classes.checkbox}
            style={{ padding: '4px 2px' }}
            checked={ressacaChecked}
            onChange={(e) => {
              setRessacaChecked(e.target.checked);
              toggleAvisos(e.target.checked, 'RESSACA');
            }}
          />
          <Typography>Ressaca</Typography>
        </Box>

        <Box
          display='flex'
          alignItems='center'
          className={classes.menuLine}
          onClick={(e) => {
            setbaixaVisibilidadeChecked((prev) => !prev);
            toggleAvisos(!ventoChecked, 'BAIXA VISIBILIDADE');
          }}
        >
          <Checkbox
            size='small'
            className={classes.checkbox}
            style={{ padding: '4px 2px' }}
            checked={baixaVisibilidadeChecked}
            onChange={(e) => {
              setbaixaVisibilidadeChecked(e.target.checked);
              toggleAvisos(e.target.checked, 'BAIXA VISIBILIDADE');
            }}
          />
          <Typography>Baixa Visibilidade</Typography>
        </Box>

        <Box
          display='flex'
          alignItems='center'
          className={classes.menuLine}
          onClick={(e) => {
            setEspecialChecked((prev) => !prev);
            toggleAvisos(!especialChecked, 'ESPECIAL');
          }}
        >
          <Checkbox
            size='small'
            className={classes.checkbox}
            style={{ padding: '4px 2px' }}
            checked={especialChecked}
            onChange={(e) => {
              setEspecialChecked(e.target.checked);
              toggleAvisos(e.target.checked, 'ESPECIAL');
            }}
          />
          <Typography>Especial</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AvisosMauTempoControl;
