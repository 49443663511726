import React, { Component, useEffect, useState } from 'react';
import { Marker, TileLayer, useMap, WMSTileLayer } from 'react-leaflet';
import * as WMS from 'leaflet.wms';
import * as L from 'leaflet';
import $ from 'jquery';
import PNBoiaShowFeatures from '../pnboia/PNBoiaShowFeatures';
import ReactDOMServer from 'react-dom/server';

const PNBoiaLayer = (props) => {
  // ORIGNAL     // L.TileLayer.BetterWMS = L.TileLayer.WMS.extend(

  // L.TileLayer.BetterWMS = L.TileLayer.WMS.extend({
  L.TileLayer.BetterWMS = L.TileLayer.WMS.extend({
    onAdd: function (map) {
      // Triggered when the layer is added to a map.
      //   Register a click listener, then do all the upstream WMS things
      L.TileLayer.WMS.prototype.onAdd.call(this, map);
      map.on('click', this.getFeatureInfo, this);
    },

    onRemove: function (map) {
      // Triggered when the layer is removed from a map.
      //   Unregister a click listener, then do all the upstream WMS things
      L.TileLayer.WMS.prototype.onRemove.call(this, map);
      map.off('click', this.getFeatureInfo, this);
    },

    getFeatureInfo: function (evt) {
      // Make an AJAX request to the server and hope for the best
      var url = this.getFeatureInfoUrl(evt.latlng),
        showResults = L.Util.bind(this.showGetFeatureInfo, this);

      $.ajax({
        url: url,
        success: function (data, status, xhr) {
          var err = typeof data === 'string' ? null : data;
          showResults(err, evt.latlng, data);
        },
        error: function (xhr, status, error) {
          showResults(error);
        },
      });
    },

    getFeatureInfoUrl: function (latlng) {
      // Construct a GetFeatureInfo request URL given a point
      var point = this._map.latLngToContainerPoint(latlng, this._map.getZoom()),
        size = this._map.getSize(),
        params = {
          request: 'GetFeatureInfo',
          service: 'WMS',
          srs: 'EPSG:4326',
          styles: this.wmsParams.styles,
          transparent: this.wmsParams.transparent,
          version: this.wmsParams.version,
          format: this.wmsParams.format,
          bbox: this._map.getBounds().toBBoxString(),
          height: size.y,
          width: size.x,
          layers: this.wmsParams.layers,
          query_layers: this.wmsParams.layers,
          //info_format: 'text/html'
          info_format: 'application/json',
        };

      params[params.version === '1.3.0' ? 'i' : 'x'] = point.x;
      params[params.version === '1.3.0' ? 'j' : 'y'] = point.y;

      return this._url + L.Util.getParamString(params, this._url, true);
    },

    showGetFeatureInfo: function (content, latlng, err) {
      // showGetFeatureInfo: function (err, latlng, content) {
      // if (err) { console.log("error",err); return; } // do nothing if there's an error
      // console.log(err);
      // Otherwise show the content in a popup, or something.
      let values = {};
      if (content) {
        if (content.features.length > 0) {
          Object.keys(content.features[0].properties).map((item) => {
            if (content.features[0].properties[item]) {
              values[item] = content.features[0].properties[item];
            }
          });

          const html = ReactDOMServer.renderToStaticMarkup(
            <PNBoiaShowFeatures values={values} />
          );

          L.popup({ maxWidth: 800 })
            .setLatLng(latlng)
            .setContent(html.toString())
            // .setContent(<PNBoiaShowFeatures />)
            // .setContent(err)
            .openOn(this._map);
        } else {
          //     console.log('sem dados');
          // this._map.flyTo(latlng, this._map.getZoom() + 1);
        }
      }
    },
  });

  const beterWms = function (url, options) {
    return new L.TileLayer.BetterWMS(url, options);
  };

  const { url, layers } = props;
  // var myRenderer = L.canvas({ padding: 0, tolerance: 0 });
  const options = {
    format: 'image/png',
    transparent: 'true',
    info_format: 'application/json',
    layers: layers,
    // renderer: myRenderer
    // identify: MySource
  };

  const map = useMap();

  const [wms, setWms] = useState(beterWms(url, options));

  if (props.checked) {
    wms.addTo(map);
    // layer.addTo(map);
  } else {
    map.removeLayer(wms);
    // setLayer(null);
  }

  // L.tileLayer.wms(url, options).addTo(map);

  return null;

  // const map = useMap();
  // const { url, layers } = props;
  // const options = {
  //     format: 'image/png',
  //     transparent: 'true',
  //     info_format: 'text/html',
  //     // identify: MySource
  // };

  // // Add WMS source/layers
  // const source = WMS.source(url, options);
  // source.options.identify = MySource;
  // source.WMSTileLayer = MySource;

  // for (let name of layers) {
  //     source.getLayer(name).addTo(map);
  // }

  // // map.on('click', function (e) {
  // //     // Build the URL for a GetFeatureInfo
  // //     console.log(source.getLayer(layers[0]));
  // // });

  // return null;

  // return (

  //     <>
  //         <WMSTileLayer
  //             url="https://idem.dhn.mar.mil.br/geoserver/ows?SERVICE=WMS&"
  //             // layers={props.layers}
  //             // options={options}
  //             format="image/png"
  //             transparent={true}
  //             zIndex={9999999}
  //             opacity={1}
  //             eventHandlers={{
  //                 click: () => {
  //                     console.log('click')
  //                 },
  //                 dblclick: () => {
  //                     console.log('dblclick')
  //                 },
  //                 preclick: () => {
  //                     console.log('dblclick')
  //                 },
  //                 add: () => {
  //                     console.log('add')
  //                 },
  //             }}
  //         />
  //     </>
  // )
};

export default PNBoiaLayer;
