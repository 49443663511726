import React from 'react';
import { Typography, makeStyles, Box, Button, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    info: {
        margin: 0,
    },
    values: {
        margin: 0,
        fontSize: 10
    },
    btn: {
        marginRight: theme.spacing(2)
    },
    btnGroup: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center'
    }
}));

const ShowFeaturesLegendLayer = props => {
    const classes = useStyles();
    const propriedades = props.render

    return (
        <div className={classes.info}>
            <div>Legenda</div>
            <div>
                {
                    Object.keys(propriedades).map((key) => {
                        return (
                            <Typography display="block" variant={"caption"}> <b> {key.toUpperCase()} </b> : <img src={propriedades[key]} /> </Typography>
                        )
                    })
                }
            </div>
        </div >
    )
}

export default ShowFeaturesLegendLayer;