import { makeStyles, Box, Typography, Button } from '@material-ui/core';
import React from 'react'
import lightBlue from '@material-ui/core/colors/lightBlue';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    divAbout: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
        height: '100%',
        background: lightBlue[900],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'center',

    },
    title: {
        paddingTop: theme.spacing(5),
        fontSize: 35,
        fontWeight: 600,
        color: "#FFF",
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            fontWeight: 600
        }
    },
    text: {
        marginTop: theme.spacing(5),
        color: "#FFF",
        width: '80%',
        textAlign: 'center'
    },
    btn: {
        margin: theme.spacing(10),
        padding: theme.spacing(1),
        border: '2px solid',
        borderColor: '#FFF',
        color: '#FFF',
        '&:hover': {
            backgroundColor: lightBlue[300],
            boxShadow: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(4),
        }

    }
}));

const About = () => {
    const classes = useStyles();

    return (
        <Box className={classes.divAbout}>
            <Typography className={classes.title}>
                SOBRE IDEM-DHN
            </Typography>
            <Typography className={classes.text}>
                A Infraestrutura de Dados Espaciais Marinhos da Diretoria de Hidrografia e Navegação  – IDEM-DHN é um conjunto integrado de tecnologias;
                políticas; mecanismos e procedimentos de coordenação e monitoramento; padrões e acordos, necessário para facilitar e ordenar a geração,
                o armazenamento, o acesso, o compartilhamento, a disseminação e o uso dos dados geoespaciais de origem federal, estadual, distrital e municipal.
            </Typography>
            <Button
                color="#FFF"
                variant="outlined"
                className={classes.btn}
                component={Link}
                to='/idem'>
                Saiba mais
            </Button>

        </Box>
    )
}

export default About;
