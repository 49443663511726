import {
  Box,
  Grid,
  Link,
  List,
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { lightBlue } from '@material-ui/core/colors';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  divFooter: {
    paddingBottom: theme.spacing(3),
    width: '100%',
    background: lightBlue[900],
  },
  divTitle: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: '#FFF',
    fontWeight: 600,
    fontFamily: 'Roboto',
  },
  divItems: {
    textAlign: 'center',
  },
  item: {
    fontSize: 11,
    color: '#FFF',
    fontFamily: 'Roboto',
  },
}));

const Footer = () => {
  const classes = useStyles();
  const marinha_items = [
    {
      text: 'Comandante da Marinha',
      link: 'https://www.marinha.mil.br/comandantedamarinha',
    },
    {
      text: 'Hinos e Canções',
      link: 'https://www.marinha.mil.br/hinos-e-cancoes',
    },
    { text: 'História Naval', link: 'https://www.marinha.mil.br/historia' },
    {
      text: 'Missão e Visão de Futuro',
      link: 'https://www.marinha.mil.br/content/missao-e-visao-de-futuro-da-marinha',
    },
    {
      text: 'Organizações Militares',
      link: 'https://www.marinha.mil.br/organizacoes-militares',
    },
    { text: 'Patrono', link: 'https://www.marinha.mil.br/patrono' },
    {
      text: 'Postos e Graduações',
      link: 'https://www.marinha.mil.br/postos-e-graduacoes',
    },
    {
      text: 'Tradições Navais',
      link: 'https://www.marinha.mil.br/tradicoes-navais',
    },
  ];

  const idem_items = [
    { text: 'IDEM-DHN', link: `${process.env.REACT_APP_IDEM_URL}` },
    {
      text: 'Visualizador de Mapas',
      link: `${process.env.REACT_APP_IDEM_URL}`,
    },
    {
      text: 'Catálogo de Metadados',
      link: `${process.env.REACT_APP_IDEM_URL}/geonetwork`,
    },
    {
      text: 'Catálogo de Geoserviços',
      link: `${process.env.REACT_APP_IDEM_URL}/geoserver`,
    },
  ];

  const dhn_items = [
    { text: 'Início', link: 'https://www.marinha.mil.br/dhn/' },
    {
      text: 'Missão/Visão',
      link: 'https://www.marinha.mil.br/dhn/?q=pt-br/node/80',
    },
    { text: 'Diretor', link: 'https://www.marinha.mil.br/dhn/?q=pt-br/foto' },
    {
      text: 'Regulamento',
      link: 'https://www.marinha.mil.br/dhn/sites/www.marinha.mil.br.dhn/files/arquivosss/Port-18-2020-DGN-An-Regulamento-DHN.pdf',
    },
    {
      text: 'Organograma',
      link: 'https://www.marinha.mil.br/dhn/sites/www.marinha.mil.br.dhn/files/arquivosss/Port18-2020-DGN-Ap-An-Regulamento-DHN.pdf',
    },
    {
      text: 'O Bode Verde',
      link: 'https://www.marinha.mil.br/dhn/?q=pt-br/node/99',
    },
  ];

  const chm_items = [
    { text: 'Início', link: 'https://www.marinha.mil.br/chm' },
    {
      text: 'Missão',
      link: 'https://www.marinha.mil.br/chm/dados-institucionais/missao',
    },
    {
      text: 'Diretor',
      link: 'https://www.marinha.mil.br/chm/dados-institucionais/diretor',
    },
    {
      text: 'Visão de Futuro',
      link: 'https://www.marinha.mil.br/chm/dados-institucionais/visao-de-futuro',
    },
    {
      text: 'BNDO',
      link: 'https://www.marinha.mil.br/chm/dados-institucionais/visao-de-futuro',
    },
    {
      text: 'Cartas Náuticas',
      link: 'https://www.marinha.mil.br/chm/dados-do-segnav-cartas-nauticas/cartas-nauticas',
    },
    { text: 'Previsão Ambiental Marinha', link: 'https://pam.dhn.mar.mil.br/' },
  ];

  return (
    <div className={classes.divFooter}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Box className={classes.divTitle}>Marinha do Brasil</Box>
          <Box className={classes.divItems}>
            {marinha_items.map((item, key) => (
              <Typography key={key}>
                <Link className={classes.item} target='_blank' href={item.link}>
                  {item.text}
                </Link>
              </Typography>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Box className={classes.divTitle}>IDEM-DHN</Box>
          <Box className={classes.divItems}>
            {idem_items.map((item, key) => (
              <Typography key={key}>
                <Link className={classes.item} target='_blank' href={item.link}>
                  {item.text}
                </Link>
              </Typography>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Box className={classes.divTitle}>
            Diretoria de Hidrografia e Navegação-DHN
          </Box>
          <Box className={classes.divItems}>
            {dhn_items.map((item, key) => (
              <Typography key={key}>
                <Link className={classes.item} target='_blank' href={item.link}>
                  {item.text}
                </Link>
              </Typography>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Box className={classes.divTitle}>
            Centro de Hidrografia da Marinha - CHM
          </Box>
          <Box className={classes.divItems}>
            {chm_items.map((item, key) => (
              <Typography key={key}>
                <Link className={classes.item} target='_blank' href={item.link}>
                  {item.text}
                </Link>
              </Typography>
            ))}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
