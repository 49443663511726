import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import geonetwork from '../assets/images/geonetwork.png';
import geoserver from '../assets/images/geoserver.png';
import visualizador from '../assets/images/visualizador.png';

const cards = [
  {
    title: 'Catálogo de Metadados',
    description:
      'Pesquise por dados existentes em uma área, período, temas  e encontre as informações para acesso aos dados disponíveis.',
    link: `${process.env.REACT_APP_IDEM_URL}/geonetwork`,
    img: geonetwork,
    route: false,
  },
  {
    title: 'Catálogo de Serviços',
    description:
      'Faça o download e a visualização de dados em diferentes formatos digitais através de geosserviços.',
    link: `${process.env.REACT_APP_IDEM_URL}/geoserver`,
    img: geoserver,
    route: false,
  },
  {
    title: 'Visualizador de Mapas',
    description:
      'Visualize rapidamente os dados e metadados de forma integrada em um mapa. Futuras funcionalidades do visualizador estarão disponíveis em breve.',
    link: '/map',
    img: visualizador,
    route: true,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  media: {
    height: 250,
    objectFit: 'contain',
  },
  grid: {
    justifyContent: 'space-between',
    flex: 1,
  },
  cardRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardActions: {
    justifyContent: 'center',
  },
}));

const ImageGrid = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Grid container className={classes.grid} spacing={3}>
        {cards.map((card, value) => (
          <Grid component='Card' item xs={12} sm={4}>
            <ImageCard card={card} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ImageGrid;

const ImageCard = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleClick = () => {
    if (props.card.route) {
      navigate(props.card.link);
    } else {
      window.open(props.card.link, '_blank');
      // window.location.href = props.card.link
    }
  };
  return (
    <Card onClick={handleClick} className={classes.cardRoot}>
      <CardActionArea>
        <CardMedia
          component='img'
          className={classes.media}
          image={props.card.img}
          // title="Contemplative Reptile"
        />
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant='h5' component='h2'>
            {props.card.title}
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p'>
            {props.card.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <Button size='small' color='primary' onClick={handleClick}>
          Saiba Mais
        </Button>
      </CardActions>
    </Card>
  );
};
