import React from 'react';
import img1 from '../assets/images/img1.jpg';
import img2 from '../assets/images/img2.jpg';
import img3 from '../assets/images/img3.jpg';
import About from '../components/About';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import ImageGrid from '../components/ImageGrid';
import ImageSlider from '../components/ImageSlider';

const Home = () => {
    const items = [
        {
            name: "",
            description: "",
            img: img1,
            link: 'https://www.marinha.mil.br/chm/dados-do-smm/corrente-de-mare'
        },        
        {
            name: "",
            description: "",
            img: img3,
            link: 'https://pam.dhn.mar.mil.br'
        },

    ];
    return (
        <div>
            <Hero />
            <ImageGrid />
            <About />
            <ImageSlider items={items} />
            <Footer />
        </div>
    )
}

export default Home;